<template>
  <div class="login">
    <div class="title">
      {{ $t("welcome") }}<br /><el-text class="mx-1" type="primary"
        >DentalCloud</el-text
      >
    </div>
    <el-tabs v-model="activeName" class="login-tabs">
      <!-- 登录 -->
      <el-tab-pane :label="$t('Invite to Register')" name="invite" lazy>
        <el-form
          ref="registerFormRef"
          label-position="left"
          :model="registerForm"
          :rules="rules"
          class="inviteForm"
        >
          <el-form-item prop="email">
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("Email")
              }}</span
              ><span class="required" style="color: red">*</span>
            </template>
            <el-input
              readonly
              v-model="registerForm.email"
              :placeholder="$t('Please enter email')"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("Contact Number")
              }}</span>
            </template>
            <el-input
              v-model="registerForm.phone"
              :placeholder="$t('Please Enter Phone Number')"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("Password")
              }}</span
              ><span class="required" style="color: red">*</span>
            </template>
            <el-input
              show-password
              v-model="registerForm.password"
              :placeholder="$t('Please enter password')"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="confirmPassword">
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("Confirm Password")
              }}</span
              ><span class="required" style="color: red">*</span>
            </template>
            <el-input
              v-model="registerForm.confirmPassword"
              show-password
              :placeholder="$t('Please enter your confirmation password')"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="username">
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("User Name")
              }}</span
              ><span class="required" style="color: red">*</span>
            </template>
            <el-input
              v-model="registerForm.username"
              :placeholder="$t('Please enter your username')"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="laboratoryName">
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("laboratory Name")
              }}</span
              ><span class="required" style="color: red">*</span>
            </template>
            <el-input
              v-model="registerForm.laboratoryName"
              :placeholder="$t('Please enter the clinic name')"
            >
            </el-input>
          </el-form-item>

          <el-form-item>
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("address")
              }}</span>
            </template>
            <el-input
              v-model="registerForm.address"
              :placeholder="$t('Please enter the address')"
            >
            </el-input>
          </el-form-item>

          <el-form-item prop="verificationCode">
            <template #label>
              <span class="custom-label" v-textTootip:auto>
                {{ $t("Verification code") }}
              </span>
              <span class="required" style="color: red">*</span>
            </template>
            <div>
              <el-input
                class="verificationCodeInput"
                v-model="registerForm.verificationCode"
                :placeholder="$t('Please enter the verification code')"
              >
              </el-input>
            </div>
            <img
              class="verificationCodeImage"
              :src="VerifyPicture.imageBase64"
            />
            <img
              class="verificationCodeRefresh"
              @click="getVerifyCode"
              src="/image/home/refresh.png"
            />
          </el-form-item>
          <el-button
            class="inviteRegister"
            type="primary"
            v-debounce:click="{ handler: register, delay: 300 }"
            >{{ $t("register") }}</el-button
          >
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
  
  <script setup>
import { loginStore, PublicStore } from "@/tools/pinia/store.js";
let loginStores = loginStore();
let PublicStores = PublicStore();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
onMounted(async () => {
  getVerifyCode();
  const code = route.query.code;
  if (code) {
    let res = await getRequest("/dental/base/verifyInviteCode", {
      InviteCode: code,
    });
    registerForm.email = res.data.split("|")[3];
    registerForm.InviteCode = code;
  }
});
// tab选中
let activeName = "invite";
let VerifyPicture = reactive({});
// 获得验证码
async function getVerifyCode() {
  let res = await getRequest("/dental/base/getCaptcha");
  if (res.code == 200) {
    VerifyPicture.ID = res.data.ID;
    VerifyPicture.imageBase64 = res.data.Image;
  }
}
// 邀请注册表单
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const registerFormRef = ref(null);
const registerForm = reactive({
  InviteCode: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  username: "",
  laboratoryName: "",
  address: "",
  verificationCode: "",
});
const verifyEmail = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter your email address")));
  } else {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      callback(new Error(t("Please input a valid email address")));
    }
    callback();
  }
};
const verifyPass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("Please enter password")));
  } else {
    callback();
  }
};
const verifyConfirmPass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("Please enter your password here")));
  } else if (value !== registerForm.password) {
    callback(new Error(t("The two passwords are inconsistent")));
  } else {
    callback();
  }
};
const verifyUsername = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter your username")));
  } else {
    callback();
  }
};
const verifylaboratoryName = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please Enter Workshop")));
  } else {
    callback();
  }
};
const verifyCode = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter the verification code")));
  } else {
    callback();
  }
};
const rules = reactive({
  email: [{ validator: verifyEmail, trigger: "blur" }],
  password: [{ validator: verifyPass, trigger: "blur" }],
  confirmPassword: [{ validator: verifyConfirmPass, trigger: "blur" }],
  username: [{ validator: verifyUsername, trigger: "blur" }],
  laboratoryName: [{ validator: verifylaboratoryName, trigger: "blur" }],
  verificationCode: [{ validator: verifyCode, trigger: "blur" }],
});
async function register() {
  registerFormRef.value.validate(async (valid) => {
    if (!valid) {
      return;
    }
    let codedata = {
      ID: VerifyPicture.ID,
      Answer: registerForm.verificationCode,
    };
    let res = await postRequest("/dental/base/verifyCaptcha", codedata);
    if (res.code != 200) {
      ElMessage({
        message: res.message,
        type: "error",
      });
      getVerifyCode();
      registerForm.verificationCode = "";
    }
    let data = {
      InvitationCode: registerForm.InviteCode,
      Password: registerForm.password,
      NickName: registerForm.username,
      Phone: registerForm.phone,
      Addr: registerForm.address,
      AddrName: registerForm.laboratoryName,
    };
    postRequest("/dental/base/registerByInvitation", data).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: res.message,
          type: "success",
        });
        resetRegisterForm();
        router.push("/");
        return;
      }
      ElMessage({
        message: res.message,
        type: "error",
      });
      getVerifyCode();
      registerForm.verificationCode = "";
    });
  });
}
function resetRegisterForm() {
  registerForm.email = "";
  registerForm.password = "";
  registerForm.confirmPassword = "";
  registerForm.username = "";
  registerForm.laboratoryName = "";
  registerForm.address = "";
  registerForm.verificationCode = "";
}
</script>
<style lang="scss" >
.inviteForm {
  .el-form-item {
    margin-bottom: 20px;
    .el-form-item__label {
      width: 108px;
      height: 40px;
      line-height: 40px;
      font-weight: 1000;
      color: #494c4f;
    }
    .el-form-item__label-wrap {
      .el-form-item__label {
        height: 40px;
        line-height: 40px;
      }
    }
    .verificationCodeInput {
      width: 180px;
    }
    .verificationCodeImage {
      width: 80px;
      height: 40px;
      margin-left: 8px;
    }
    .verificationCodeRefresh {
      width: 32px;
      height: 32px;
    }
    .el-input {
      height: 40px;
    }
  }
  .inviteRegister {
    width: 100% !important;
    height: 64px;
  }
  .el-form-item:nth-child(1) {
    margin-top: 17px;
  }
}
</style>
  <style scoped lang="scss">
.login {
  width: 800px;
  height: 100%;
  padding: 80px 196px;
  box-sizing: border-box;
  background: #f6f8fa;
  float: right;
  .title {
    width: 100%;
    height: 100px;
    background: #f6f8fa;
    font-size: 24px;
    font-weight: 500;
    line-height: 38.4px;
    letter-spacing: 0.05em;
    text-align: left;
    .el-text {
      font-size: 36px;
      font-weight: 700;
      line-height: 57.6px;
      letter-spacing: 0.05em;
      text-align: left;
    }
  }
  :deep(.login-tabs) {
    margin-top: 20px;
  }
  :deep(.el-tabs__header) {
    .el-tabs__nav {
      height: 56px;
    }
    .el-tabs__item {
      height: 56px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      // padding: 0 36px;
    }
    .el-tabs__active-bar {
      height: 4px;
    }
  }
}
</style>
  