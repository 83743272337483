//  3D图像公用方法以mixin混入
import { reactive, ref } from 'vue';

export function basemixin() {
    let queueBuffer = ref(QueurBuffer.CreateNew())//消息发送队列
    let mousePos = reactive({ x: 0, y: 0 })
    let isOpened = ref(false)//当前页是否打开过
    let tick = ref(0)//控制消息发送的间隔
    let decodeNum = ref(0)//正在发送的消息条数
    let dec = ref(4)
    let currentCanvas = ref("")//鼠标按下时canvas所在的
    let mouseIsDown = ref(false)
    let shift = ref(null)


    // 创建webGl
    function initWebGl( View3dCanvas) {
        View3dCanvas.forEach((item, index) => {
            item.element = WebGLCanvas3D.CreateNew(
                dec.value,
                item.OrthoView,
                3,
                item,
                item.Num
            );
        });
    }
    // 判断是鼠标是左键还是右键还是滚轮
    function getButtonShift(event) {
        let shift = {
            left: 0,
            right: 0,
        };
        if (event.buttons == 1) {
            shift.left = 1;
        } else if (event.buttons == 2) {
            shift.right = 1;
        }
        return shift;
    }
    function onSendTimer() {
        this.tick++;
        if (this.decodeNum == 0) {
            if (this.tick < 10) {//10ms一次
                return;
            }
        } else if (this.decodeNum == 1) {
            if (this.tick < 10) {//20ms一次
                return;
            }
        } else if (this.decodeNum == 2) {
            if (this.tick < 30) {//40ms一次
                return;
            }
        } else if (this.decodeNum > 2 && this.decodeNum < 6) {
            if (this.tick < 80) {//80ms一次
                return;
            }
        } else {
            return;
        }
        this.tick = 0;
        let msg = this.queueBuffer.Pop();

        if (msg == null) {
            return;
        }
        this.SendNet(msg.value, msg.canMerge);
    }
    function SendNet(obj) {
        this.$ws.sendWebsocketRequest1(JSON.stringify(obj))
    }
    function Send(obj, canIgnore) {
        queueBuffer.value.Push(obj, canIgnore);
    }
    function setViewSize(View3dCanvas) {
        let calc_wh = function (_v) {
            _v = _v - _v % 4;
            return _v;
        }
        for (let i = 0, len = View3dCanvas.length; i < len; i++) {
            let vname = View3dCanvas[i].OrthoView
            // 暂时注释%4
            let parentElement = document.querySelector(`#${vname}`).parentNode;
            let rect = parentElement.getBoundingClientRect();
            let cW = calc_wh(rect.width);
            let cH = calc_wh(rect.height);
            if (cW == 0 || cH == 0) {
                // isZero = true
            }
            if (cW > 0) {
                View3dCanvas[i].width = cW;
            }
            if (cH > 0) {
                View3dCanvas[i].height = cH;
            }
            sysCanvasSize(vname, View3dCanvas, cW, cH, i);
        }
    }
    function sysCanvasSize(canvasid, View3dCanvas, sizeW, sizeH, i) {
        if (sizeW == 0 || sizeH == 0) {
            console.log('宽高为0 canvasid=', canvasid)
            //如果有为0的则不处理
        } else {
            try {
                View3dCanvas[i]['element'].sizeData.width = sizeW;
                View3dCanvas[i]['element'].sizeData.height = sizeH;
                View3dCanvas[i]['element'].setSize();
            } catch (err) {
                console.log('sysCanvasSize err=', err.message, 'canvasid=', canvasid, sizeW, sizeH, i);
            }
        }
    }
    function onHide(View3dCanvas) {
        try {
            for (var i = 0, len = View3dCanvas.length; i < len; i++) {
                View3dCanvas[i].element.Hide();
            }
        } catch (err) {
            console.log('onHide err=', vname, this.canvasArr);
        }
    }
    function onShow(View3dCanvas) {
        try {
            for (var i = 0, len = View3dCanvas.length; i < len; i++) {
                View3dCanvas[i].element.Show();
            }
        } catch (err) {
            console.log('this.canvasArr=');
        }
    }
    function SetImage(msg, image) {
        let obj = this.canvasArr.find(item => {
            return item.num == msg.id
        });
        obj[obj.name].DrawImage(msg, image);
    }
    // 获取鼠标的坐标点s
    function swappos(e) {
        let x = e.offsetX;
        let y = e.offsetY;
        return {
            x: parseInt(x),
            y: parseInt(y),
        };
    }
    // 鼠标操作
    function MouseWheel(e, item) {
        let wheelDelta = window.event.wheelDelta;
        let pos = swappos(e);
        mousePos = pos;
        let wsData = {
            id: item.Num,
            x: pos.x,
            y: pos.y,
            WheelDelta: wheelDelta,
            // Msgcommand: comWebSocketMouseWheel,
        };
        Send(wsData, false);
    }
    function OnMouseMove(e, item) {
        if (mouseIsDown.value == true && currentCanvas.value != item.OrthoView) {
            return;
        }
        let pos = swappos(e);
        mousePos = pos;
         shift.value = getButtonShift(e);
        let wsData = {
            // Msgcommand: comWebSocketMouseMove,
            x: pos.x,
            y: pos.y,
            shift: shift.value,
            id: item.Num,
        };
        // this.$ws.sendWebsocketRequest(wsData);
        Send(wsData, false);
    }
    function OnMouseDown(e, item, View3dCanvas) {
        currentCanvas.value = item.OrthoView;
        let pos = swappos(e);
         shift.value = getButtonShift(e);
        mousePos = pos;
        if (currentCanvas.value == "OrthoView0") {
            // document.onmouseup = MouseUp0;
            document.onmouseup = () => OnMouseUp(item.Num);
        } else if (currentCanvas.value == "OrthoView1") {
            document.onmouseup = () => OnMouseUp(item.Num);
        } else if (currentCanvas.value == "OrthoView2") {
            document.onmouseup = () => OnMouseUp(item.Num);
        } else if (currentCanvas.value == "OrthoView3D") {
            document.onmouseup = () => OnMouseUp(item.Num);
        }
        mouseIsDown.value = true;
        let wsData = {
            id: item.Num,
            x: pos.x,
            y: pos.y,
            shift: shift.value,
            // Msgcommand: comWebSocketMouseDown,
        };
        // this.$ws.sendWebsocketRequest(wsData);
        Send(wsData, false);
        for (let index = 0; index < View3dCanvas.length; index++) {
            View3dCanvas[index].showScroll = false;
        }
        item.showScroll = true;
        console.log(wsData, 'wsDatawsDatawsDatawsDatawsData');
    }
    // function MouseUp0(Num) {
    //     OnMouseUp(Num);
    // }
    // function MouseUp1(Num) {
    //     OnMouseUp(Num);
    // }
    // function MouseUp2(Num) {
    //     OnMouseUp(Num);
    // }
    // function MouseUp3D(Num) {
    //     OnMouseUp(Num);
    // }
    function OnMouseUp(id) {
        mouseIsDown.value = false;
        let wsData = {
            // Msgcommand: comWebSocketMouseUp,
            id: id,
            x: mousePos.x,
            y: mousePos.y,
            shift: shift.value,
        };
        // this.$ws.sendWebsocketRequest(wsData);
        Send(wsData, false);
    }
    function OnDblclick(e, item) {
        let pos = swappos(e);
        mousePos = pos;
        let wsData = {
            id: item.Num,
            x: pos.x,
            y: pos.y,
            // Msgcommand: comWebSocketMouseDblClk,
        };
        // this.$ws.sendWebsocketRequest(wsData);
        Send(wsData, false);
    }








    return {
        initWebGl,
        queueBuffer,
        mousePos,
        isOpened,
        tick,
        decodeNum,
        dec,
        getButtonShift,
        onSendTimer,
        SendNet,
        Send,
        setViewSize,
        sysCanvasSize,
        onHide,
        onShow,
        SetImage,
        MouseWheel,
        OnMouseMove,
        OnMouseDown,
        OnDblclick,
    };
}
