/*
 * @Description: 
 * @Autor: zt
 * @Date: 2023-09-26 16:08:46
 * @LastEditTime: 2024-09-13 18:34:00
 */
import { createI18n } from 'vue-i18n';

let messages = {};

let langlist = window.g.langlist
let path = langlist.map(item => {
    return item.title
})
// let langURL = window.g.serverUrl;
path.forEach(item => {
    // 改成可以读取外部翻译的形式
    let xhr = new XMLHttpRequest();
    xhr.open('GET', `/static/lang/${item}.json`, false);
    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
            if (xhr.status == 200 || xhr.status == 304) {
                let res = JSON.parse(xhr.response)
                messages[item] = {
                    ...res
                };
            }
        }
    }
    xhr.send();
})
export const VueI18n = createI18n({
    locale: 'en',
    messages: messages,
    legacy: false, // 禁用遗留模式，启用组合式 API 模式
    globalInjection: true, // 全局注册$t方法
    silentTranslationWarn: true, // 去除国际化警告
    formatFallbackMessages: true,
})
