<template>
  <div class="MPRLeftToolbar">
    <el-collapse class="Collapse" v-model="collapseNames">
      <!-- 3D调整视图 -->
      <el-collapse-item class="viewAdjust" name="0">
        <template #title
          ><span class="collapse-title">{{ $t("3DAdjustView") }}</span>
        </template>
        <div class="content">
          <div class="Top">
            <el-select
              class="viewAdjustSelect"
              popper-class="viewAdjustStyle"
              v-model="selectValue"
              :placeholder="placeholder"
              @change="threeDInputSelect"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="(item, index) in threeDAdjustArr.selectData"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">{{ item.label }}</span>
                <i
                  style="float: right; font-size: 13px"
                  v-if="selectValue == item.value"
                  class="tickIcon"
                ></i>
              </el-option>
            </el-select>

            <div class="ToolBtn">
              <btnImg
                v-for="item in threeDAdjustArr.tool"
                :key="item.name"
                :boxSizeClass="'btnImg30'"
                :active="item.Active"
                :imgSrc="item.imgSrc"
                :imgSrcHover="item.imgSrcHover"
                :imgSrcActive="item.imgSrcActive"
                :independent="item.independent"
                :btnName="item.label"
              ></btnImg>
            </div>
          </div>
          <div class="footer">
            <div
              class="brightnessCard"
              v-for="item in threeDAdjustArr.silderData"
              :key="item.num"
            >
              <div class="brightnessSlider">
                <img
                  class="brightnessIcon"
                  :src="item.img"
                  alt=""
                  draggable="false"
                />
                <el-slider
                  v-model="item.brightnessValue"
                  :show-tooltip="false"
                  @input="threeDInputSlider(item)"
                ></el-slider>
                <p style="margin: 0">{{ item.brightnessValue }}</p>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <!-- 视图 -->
      <el-collapse-item class="titleView" name="1">
        <template #title>
          <span class="collapse-title">{{ $t("tool") }}</span>
        </template>
        <div class="tool" v-for="(item, index) in viewTool" :key="index">
          <btnImg
            :boxSizeClass="'btnImg60'"
            :active="item.Active"
            :imgSrc="item.imgSrc"
            :imgSrcHover="item.imgSrcHover"
            :imgSrcActive="item.imgSrcActive"
            :key="item.name"
            :independent="item.independent"
            :btnName="item.label"
            @click="switchTool(item)"
          ></btnImg>
        </div>
      </el-collapse-item>
      <!-- 测量 -->
      <el-collapse-item class="titleMeasure" name="2">
        <template #title>
          <span class="collapse-title">{{ $t("titleMeasure") }}</span>
        </template>
        <div class="tool" v-for="(item, index) in MeasureTool" :key="index">
          <btnImg
            :boxSizeClass="'btnImg60'"
            :active="item.Active"
            :imgSrc="item.imgSrc"
            :imgSrcHover="item.imgSrcHover"
            :imgSrcActive="item.imgSrcActive"
            :key="item.name"
            :independent="item.independent"
            :btnName="item.label"
            @click="switchTool(item)"
          ></btnImg>
        </div>
      </el-collapse-item>
      <!-- 消息对象· -->
      <el-collapse-item class="messageObject" name="3">
        <template #title>
          <span class="collapse-title">{{ $t("MessageObject") }}</span>
        </template>
        <div>
          <ul>
            <li
              class="liItem"
              v-for="info in messageObject"
              :key="info.ObjectIndex"
            >
              <div v-show="info.VisableState == 1">
                <btnImg
                  v-for="item in info.onEyesToll"
                  :key="item.name"
                  :boxSizeClass="'btnImg16'"
                  :active="item.Active"
                  :imgSrc="item.imgSrc"
                  :imgSrcActive="item.imgSrcActive"
                  :imgSrcHover="item.imgSrcHover"
                  :independent="item.independent"
                  :btnName="item.label"
                  @click="trigger(item, info)"
                ></btnImg>
              </div>
              <div v-show="!info.VisableState == 1">
                <btnImg
                  v-for="item in info.offEyesToll"
                  :key="item.name"
                  :boxSizeClass="'btnImg16'"
                  :active="item.Active"
                  :imgSrc="item.imgSrc"
                  :imgSrcActive="item.imgSrcActive"
                  :imgSrcHover="item.imgSrcHover"
                  :independent="item.independent"
                  :btnName="item.label"
                  @click="trigger(item, info)"
                ></btnImg>
              </div>
              <el-tooltip
                :disabled="tooltipDisabled"
                effect="dark"
                :content="info.text"
                placement="top"
              >
                <p
                  class="contentnum gap"
                  @mouseenter="spanMouseenter($event)"
                  :style="{
                    color: '#E5E5E5',
                    width: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }"
                >
                  {{ info.text }}
                </p>
              </el-tooltip>
              <btnImg
                v-for="item in info.messageObjectTool"
                :key="item.name"
                :boxSizeClass="'btnImg16'"
                :active="item.Active"
                :imgSrc="item.imgSrc"
                :imgSrcActive="item.imgSrcActive"
                :imgSrcHover="item.imgSrcHover"
                :independent="item.independent"
                :btnName="item.label"
                @click="trigger(item, info)"
              ></btnImg>
            </li>
          </ul>
        </div>
      </el-collapse-item>
      <!-- 汇出 -->
      <el-collapse-item class="remit" name="4">
        <template #title>
          <span class="collapse-title">{{ $t("Remit out") }}</span>
        </template>
        <div
          class="tool"
          v-for="item in remitArray"
          :key="item.id"
          :v-limit="item.name"
        >
          <btnImg
            :boxSizeClass="'btnImg60'"
            :active="item.Active"
            :imgSrc="item.imgSrc"
            :imgSrcActive="item.imgSrcActive"
            :imgSrcHover="item.imgSrcHover"
            :key="item.name"
            :independent="item.independent"
            :btnName="item.label"
            @click="switchTool(item)"
          ></btnImg>
        </div>
      </el-collapse-item>
      <!-- 箭头标注 -->
      <!-- <textModal
        :OnShow="OnShow"
        :coordinateX="PosX"
        :coordinateY="PosY"
        :content="content"
        @MarkersContent="MarkersContent"
        :placeholder="placeholder"
      ></textModal> -->
    </el-collapse>
  </div>
</template>

<script setup>
import btnImg from "@/components/common/btnImg.vue";
const { t } = useI18n();
// import textModal from "@/components/Common/textModel";
const props = defineProps({
  viewTool: {
    type: Array,
  },
  MeasureTool: {
    type: Array,
  },
  canvasArr: {
    type: Array,
  },
  defaultAtive: {
    type: String,
  },
});
let collapseNames = reactive(["0", "1", "2", "3", "4"]); //将el-collapse组件设置默认全部展开
let selectValue = ref("");
let placeholder = ref("");
function threeDInputSelect(val) {
  console.log(val);
}
let threeDAdjustArr = reactive({
  selectData: [
    {
      value: "1",
      label: t("tooth"),
    },
    {
      value: "2",
      label: t("bone"),
    },
    {
      value: "3",
      label: t("SoftTissue"),
    },
    {
      value: "4",
      label: t("GrayscaleDisplay"),
    },
    {
      value: "5",
      label: t("AirwayAnalysis"),
    },
  ],
  silderData: [
    {
      img: "/image/siderTool/81-最小.png",
      brightnessValue: 50,
      num: 1,
      name: "brightness",
    },
    {
      img: "/image/siderTool/80-最大.png",
      brightnessValue: 60,
      num: 2,
      name: "contrast",
    },
    {
      img: "/image/siderTool/min亮度.png",
      brightnessValue: 70,
      num: 3,
      name: "pictureAdd",
    },
  ],
  tool: [
    {
      label: t("Edit template"),
      name: "EditTemplate",
      Active: false,
      independent: true,
      imgSrc: "/image/siderTool/EditTemplate.png",
      imgSrcActive: "/image/siderTool/Active/AEditTemplate.png",
      imgSrcHover: "/image/siderTool/Hover/HEditTemplate.png",
    },
    {
      label: t("reset"),
      name: "reset",
      Active: false,
      independent: true,
      imgSrc: "/image/siderTool/reset.png",
      imgSrcActive: "/image/siderTool/Active/Areset.png",
      imgSrcHover: "/image/siderTool/Hover/Hreset.png",
    },
  ],
});
let messageObject = reactive([]);
let remitArray = reactive([
  {
    id: "1",
    label: t("Full screen capture"),
    name: "FullScreenCapture",
    Active: false,
    independent: true,
    imgSrc: "/image/siderTool/相机.png",
    imgSrcActive: "/image/siderTool/Active/A相机.png",
    imgSrcHover: "/image/siderTool/Hover/H相机.png",
  },
  {
    id: "2",
    label: t("Report"),
    name: "Report",
    Active: false,
    independent: true,
    imgSrc: "/image/siderTool/报告.png",
    imgSrcActive: "/image/siderTool/Active/A报告.png",
    imgSrcHover: "/image/siderTool/Hover/H报告.png",
  },
]);
</script>

<style lang="scss">
.viewAdjustSelect {
  width: 110px;
  height: 30px;
  .el-select__wrapper.is-focused {
    box-shadow: 0 0 0 1px #07c160 inset;
  }
  .el-select__wrapper {
    min-height: 30px;
    background: #282828;
    box-shadow: 0 0 0 1px #545454 inset;
    .el-select__selected-item {
      color: #fff;
    }
  }
}
.viewAdjustStyle {
  background: #282828;
  border: 0 !important;
  margin-top: -5px;
  .el-popper__arrow {
    display: none;
  }

  .el-select-dropdown__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999999;
    background: #282828;
    height: 30px;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 26px;
  }
  .is-selected {
    font-weight: 100;
    //   // select的选中带勾效果
    .tickIcon {
      width: 14px;
      height: 14px;
      display: block;
      background: url("image/siderTool/勾.png");
    }
  }
  .el-select-dropdown__item:hover {
    color: #fff;
    background: #07c160;
    .tickIcon {
      width: 14px;
      height: 14px;
      display: block;
      background: url("image/siderTool/勾1.png");
    }
  }

  .selected {
    background: var(--el-color-primary) !important;
    color: #fff;
  }

  .el-select-dropdown__list {
    padding: 4px 0;
    min-height: 20px;
  }
}
</style>
<style lang="scss" >
.MPRLeftToolbar {
  background: #151515;
  height: 100%;
  padding-left: 6px;
  overflow-y: auto;
  .tooltip-container {
    width: 20px; /* 设置容器宽度 */
    overflow: hidden; /* 隐藏溢出部分 */
  }
  .collapse-title {
    flex: 1 0 10%;
    order: 1;
    font-size: 14px;
    text-align: left;
  }
  .el-collapse {
    border: 0;
  }
  .el-collapse-item {
    width: 210px;
  }

  .el-collapse-item__wrap {
    border: 0;
  }
  .el-collapse-item__header {
    height: 30px;
    color: #ccc;
    border: 0;
    box-sizing: border-box;
    padding-left: 10px;
  }
  .viewAdjust {
    background: #1b1b1b;
    margin: 6px auto;
    .collapse-title {
      flex: 1 0 10%;
      order: 1;
      text-align: left;
    }
    .el-collapse-item__header {
      border-radius: 6px 6px 6px 6px;
      background: #3d3d3d !important;
    }
    .is-active {
      border-radius: 6px 6px 0 0 !important;
    }
    .el-collapse-item__wrap {
      border-radius: 0 0 6px 6px;
      .el-collapse-item__content {
        width: 100%;
        background: #3d3d3d;
        padding: 10px;
        box-sizing: border-box;
        .el-input__inner {
          width: 100%;
          height: 30px;
          background: #282828;
          outline: none;
          color: #fff;
          border: 1px solid #545454;
        }
      }
    }
    .content {
      .Top {
        display: flex;
        justify-content: space-between;
        height: 24px;
        // 设置下拉框样式
        // .viewAdjustSelect {
        //   margin-top: 2px !important;
        //   border: 0;
        //   background: #282828;
        //   // padding: 4px 0 4px 0;
        //   .el-select-dropdown__wrap {
        //     margin-bottom: 4px !important;
        //     overflow-x: hidden;
        //   }
        //   .el-select-dropdown__list {
        //     max-height: 120px;
        //     padding: 4px 0;
        //   }
        //   .el-scrollbar {
        //     border-radius: 4px;
        //   }
        //   .popper__arrow {
        //     visibility: hidden;
        //   }
        //   .el-select-dropdown__item {
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;
        //     color: #999999;
        //     background: #282828;
        //     font-weight: bold;
        //     height: 30px;
        //     width: 100%;
        //     padding-right: 10px;
        //     padding-left: 10px;
        //     line-height: 30px;
        //   }
        //   .el-select-dropdown__item:hover {
        //     color: #fff;
        //     background: #cdcdcd;
        //   }
        //   // select的选中带勾效果
        //   .tickIcon {
        //     width: 14px;
        //     height: 14px;
        //     display: block;
        //     background: url("../../../../assets/imgs/siderTool/勾1.png");
        //   }
        //   .selected {
        //     background: #07c160 !important;
        //     color: #fff;
        //   }
        // }
        .el-select__caret {
          line-height: 30px;
        }
        .ToolBtn {
          display: flex;
          // .Reset {
          //   width: 30px;
          //   height: 30px;
          //   // background: url("../../../../assets/imgs/siderTool/编辑8.png");
          // }
          // .edit {
          //   width: 30px;
          //   height: 30px;
          //   // background: url("../../../../assets/imgs/siderTool/重置69.png");
          // }
        }
      }
      .footer {
        min-height: 120px;
        .brightnessCard {
          margin-top: 8px;
          .brightnessIcon {
            width: 30px;
            height: 30px;
            float: left;
          }

          .brightnessSlider {
            height: 30px;
            color: #fff;
            box-sizing: border-box;
            overflow: hidden;
            .el-slider {
              float: left;
              margin: 0 18px;
              width: 100px;
              position: relative;

              .el-slider__bar {
                height: 3px;
                background: rgb(158, 158, 158) !important;
              }

              .el-slider__runway {
                height: 3px;
                background: rgb(49, 50, 51) !important;
                margin: 14px 0;
              }

              .el-slider__button-wrapper {
                position: absolute;
                top: -8px;
                width: 10px;
                height: 10px;

                .el-slider__button {
                  width: 10px;
                  height: 10px;
                  border: 0;
                }
              }
            }
            p {
              height: 30px;
              line-height: 30px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .titleView {
    margin: 0 auto;
    .el-collapse-item__header {
      border-radius: 6px 6px 0 0;
      background: #3d3d3d;
    }
    .el-collapse-item__content {
      padding: 9px;
      display: flex;
      flex-wrap: wrap;
      background: #3d3d3d;
      .tool {
        width: 33.3%;
        height: 66px;
      }
    }
  }
  .titleMeasure {
    margin: 0 auto 6px;
    .el-collapse-item__header {
      background: #3d3d3d;
      border-radius: 0px 0px 6px 6px;
    }
    .is-active {
      border-radius: 0px 0px 0px 0px !important;
    }
    .el-collapse-item__content {
      padding: 9px;
      display: flex;
      flex-wrap: wrap;
      background: #3d3d3d;
      .tool {
        width: 33.3%;
        height: 66px;
      }
    }
    .el-collapse-item__wrap {
      border-radius: 0px 0px 6px 6px;
    }
  }
  .remit {
    margin: 0 auto;
    .el-collapse-item__header {
      border-radius: 6px 6px 6px 6px;
      background: #3d3d3d;
    }
    .is-active {
      border-radius: 6px 6px 0 0 !important;
    }
    .el-collapse-item__wrap {
      border-radius: 0px 0px 6px 6px;
    }
    .el-collapse-item__content {
      padding: 9px;
      display: flex;
      flex-wrap: wrap;
      background: #3d3d3d;
      .tool {
        width: 33.3%;
        height: 66px;
      }
    }
  }
  .messageObject {
    margin: 0 auto;
    .collapse-title {
      flex: 1 0 10%;
      order: 1;
      text-align: left;
    }
    .el-select {
      margin-bottom: 10px;
    }
    .el-collapse-item__header {
      border-radius: 6px 6px 6px 6px;
      background: #3d3d3d;
    }
    .is-active {
      border-radius: 6px 6px 0 0 !important;
    }
    .el-collapse-item__wrap {
      background: #3d3d3d;
      border-radius: 0px 0px 6px 6px;
    }
    margin-bottom: 6px;
    .el-collapse-item__content {
      min-height: 50px;
      max-height: 260px;
      padding: 6px 8px 6px 6px;
      overflow-y: auto;
      &::-webkit-scrollbar-thumb {
        background: #626262;
        border-radius: 4px;
      }
    }

    .el-input--suffix .el-input__inner {
      height: 24px;
      background: #282828;
      outline: none;
      color: #c0c4cc;
      border: 1px solid #626262;
    }

    .el-select__caret {
      margin-right: 10px;
      text-align: center;
      height: 24px;
      line-height: 24px;
    }

    .liItem {
      display: flex;
      align-items: center;
      height: 30px;
      margin-bottom: 5px;
      background: #545454;
      border-radius: 4px;
      padding: 0 5px;
      box-sizing: border-box;
      .contentnum {
        flex: 1;
      }
    }

    .gap {
      margin-left: 5px;
    }
  }
  &::-webkit-scrollbar-thumb {
    background: #626262;
    border-radius: 4px;
  }
}
</style>