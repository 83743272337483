// https://www.npmjs.com/package/reconnecting-websocket
import ReconnectingWebSocket from 'reconnecting-websocket';
class WebSocketService {
    constructor(url, token,TimeZone) {
        this.socket = null;
        this.url = `${url}?AccessToken=${token}&TimeZone=${TimeZone}`; // 将 token 作为查询参数添加
        this.listeners = [];
    }

    // 初始化WebSocket连接
    connect() {
        this.socket = new ReconnectingWebSocket(this.url, null, { debug: true, reconnectInterval: 3000 });
        this.socket.onopen = () => {
            console.log("已连接,连接成功");
        };

        this.socket.onmessage = (event) => {
            this.listeners.forEach((callback) => callback(JSON.parse(event.data)));
        };

        this.socket.onclose = () => {
            console.log("已关闭，连接关闭");
        };

        this.socket.onerror = (error) => {
            console.error("WebSocket error", error);
        };
    }

    // 发送消息
    send(message) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message);
        } else {
            console.error("WebSocket is not open. Cannot send message.");
        }
    }

    // 添加消息事件监听器
    addListener(callback) {
        this.listeners.push(callback);
    }

    // 移除消息事件监听器
    removeListener(callback) {
        this.listeners = this.listeners.filter(listener => listener !== callback);
    }

    // 关闭连接
    close() {
        if (this.socket) {
            this.socket.close();
        }
    }
}

export default WebSocketService;
