<template>
  <div
    class="btnImg"
    :class="boxSizeClass"
    :title="btnName"
    @click="handleClick($event)"
  >
    <img
      id="hoverimg"
      v-show="!activeClassName"
      @mouseenter="mouseenterImg"
      @mouseleave="mouseleaveImg"
      :src="hovered ? imgSrcHover : imgSrc"
      draggable="false"
    />
    <img v-show="activeClassName" :src="imgSrcActive" draggable="false" />
  </div>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from "vue";

const props = defineProps({
  text: {
    type: Boolean,
    default: false,
  },
  imgSrc: {
    type: String,
    default: "",
  },
  imgSrcActive: {
    type: String,
    default: "",
  },
  active: {
    type: Boolean,
    default: false,
  },
  btnName: {
    type: String,
    default: null,
  },
  independent: {
    type: Boolean,
    default: false,
  },
  imgSrcHover: {
    type: String,
    default: "",
  },
  boxSizeClass: {
    type: String,
    default: "",
  },
  //   imgWidth: {
  //     type: String,
  //     default: '',
  //   },
  //   imgHeight: {
  //     type: String,
  //     default: '',
  //   },
});

const emit = defineEmits(["click"]);

const activeClassName = ref(false);
const timer = ref(null);
const hovered = ref(false);

watch(
  () => props.active,
  (nv) => {
    activeClassName.value = nv;
  }
);

const mouseenterImg = () => {
  hovered.value = true;
};

const mouseleaveImg = () => {
  hovered.value = false;
};

const handleClick = () => {
  emit("click", !props.active);
  if (props.independent) {
    timer.value = setTimeout(() => {
      activeClassName.value = false;
    }, 150);
  }
};

onBeforeUnmount(() => {
  clearTimeout(timer.value);
});
</script>

<style lang="scss" scoped>
.btnImg {
  overflow: hidden;
  cursor: pointer;
}
.btnImg16 {
  width: 16px;
  height: 16px;
  overflow: hidden;
  cursor: pointer;
}
.btnImg28 {
  width: 28px;
  height: 28px;
  overflow: hidden;
  cursor: pointer;
}
.btnImg30 {
  width: 30px;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}
.btnImg60 {
  width: 60px;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
}
.text {
  color: #fff;
}
.imgBtnDisabled {
  background-color: #b9b6b6 !important;
  cursor: not-allowed;
}
.tip {
  position: absolute;
  background: #444444;
  height: 36px;
  border-radius: 2px;
  line-height: 36px;
  padding: 0 10px;
  font-size: 12px;
  color: #fff;
  top: -40px;
  text-align: center;
}
img {
  width: 100%;
  height: 100%;
}
</style>
