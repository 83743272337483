<!--
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-07-17 18:47:17
 * @LastEditTime: 2024-08-22 13:30:22
-->
<template>
  <div class="bg">
    <loginPop v-if="islogin == undefined" />
    <invitePop v-else />
  </div>
</template>
<script setup>
import loginPop from "@/components/login/loginPop.vue";
import invitePop from "@/components/login/invitePop.vue";
const route = useRoute();
let islogin = ref(route.query.code);
watch(
  () => route.query.code,
  (newCode) => {
    islogin.value = newCode;
  }
);
</script>

<style  scoped lang="scss">
.bg {
  width: 100%;
  height: 100%;
  background: url("/image/login/background.png") no-repeat;
  background-size: cover;
}
</style>
