<!--
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-07-23 18:23:31
 * @LastEditTime: 2024-09-10 15:37:58
-->
<template>
  <el-tooltip v-if="tipShow" :content="tip">
    <div
      class="tip"
      :class="iconClass"
      :style="`background:url(${url});`"
      @click="handleClick"
    >
      <slot></slot>
    </div>
  </el-tooltip>

  <div v-else>
    <div
      class="tip"
      :class="iconClass"
      :style="`background:url(${url});`"
      @click="handleClick"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  width: {
    type: String,
    default: "16",
  },
  height: {
    type: String,
    default: "16",
  },
  right: {
    type: String,
    default: "0",
  },
  url: String,
  tip: String,
  tipShow: {
    type: Boolean,
    default: true,
  },
  iconClass: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["click"]);

function handleClick() {
  emit("click");
}
</script>

<style  lang="scss" scoped>
.tip {
  background-size: cover !important;
  display: inline-block;
  cursor: pointer;
}
.icon40 {
  width: 40px;
  height: 40px;
}
.icon32 {
  width: 32px;
  height: 32px;
}
.icon48 {
  width: 48px;
  height: 48px;
}
.icon32right12 {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
</style>
