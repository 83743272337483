<template>
  <div class="StlDialogContent" @contextmenu="handleMouseDown">
    <div id="ImplantPreView3D"></div>
    <!-- 模型精度条调整 -->
    <div class="ModelAdjustment">
      <div
        v-for="item in sliderData"
        v-show="item.mesh != null"
        :key="item.type"
        class="sliderbox"
      >
        <i :class="item.iconClass"></i>
        <div>
          <el-slider
            :min="0"
            :max="1"
            :step="0.01"
            v-model="item.sliderValue"
            @input="changeSliderValue(item)"
          />
        </div>
        <!-- <tipIcom
        v-show="item.eyeState"
                iconClass="icon40"
                url="/image/home/eyeOn.png"
                :tip="t('show')"
                @click="showModelPart(item)"
              ></tipIcom>
        <tipIcom
                v-show="!item.eyeState"
                iconClass="icon40"
                url="/image/home/eyeClose.png"
                :tip="t('hide')"
                @click="showModelPart(item)"
              ></tipIcom> -->
        <i
          class="eyeOn"
          @click="showModelPart(item)"
          v-show="item.eyeState"
        ></i>
        <i
          class="eyeClose"
          @click="showModelPart(item)"
          v-show="!item.eyeState"
        ></i>
      </div>
    </div>
    <!-- 工具栏 -->
    <div class="fullScreenTool">
      <tipIcom
        iconClass="icon48"
        :tip="fullScreenShow ? $t('Cancel Full Screen') : $t('Full Screen')"
        :url="
          fullScreenShow
            ? '/image/home/noFullScreen.png'
            : '/image/home/fullScreen.png'
        "
        @click="fullScreen"
      ></tipIcom>
    </div>
    <div class="toolbar">
      <el-dropdown popper-class="viewPopper" @command="handleCommand">
        <span>
          <tipIcom
            :tipShow="false"
            iconClass="icon48"
            :url="
              ActiveCube == ''
                ? '/image/home/SixViews.png'
                : '/image/home/ASixViews.png'
            "
          ></tipIcom>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="1">
              <tipIcom
                iconClass="icon48"
                :tip="$t('Anterior View')"
                :url="
                  ActiveCube == '1'
                    ? '/image/home/ASixViews1.png'
                    : '/image/home/SixViews1.png'
                "
              ></tipIcom
            ></el-dropdown-item>
            <img class="custom-spacer" src="/image/home/Line .png" />
            <el-dropdown-item command="2">
              <tipIcom
                iconClass="icon48"
                :tip="$t('Posterior View')"
                :url="
                  ActiveCube == '2'
                    ? '/image/home/ASixViews2.png'
                    : '/image/home/SixViews2.png'
                "
              ></tipIcom
            ></el-dropdown-item>
            <img class="custom-spacer" src="/image/home/Line .png" />
            <el-dropdown-item command="3">
              <tipIcom
                iconClass="icon48"
                :tip="$t('Left View')"
                :url="
                  ActiveCube == '3'
                    ? '/image/home/ASixViews3.png'
                    : '/image/home/SixViews3.png'
                "
              ></tipIcom
            ></el-dropdown-item>
            <img class="custom-spacer" src="/image/home/Line .png" />
            <el-dropdown-item command="4">
              <tipIcom
                iconClass="icon48"
                :tip="$t('Right View')"
                :url="
                  ActiveCube == '4'
                    ? '/image/home/ASixViews4.png'
                    : '/image/home/SixViews4.png'
                "
              ></tipIcom
            ></el-dropdown-item>
            <img class="custom-spacer" src="/image/home/Line .png" />
            <el-dropdown-item command="5">
              <tipIcom
                iconClass="icon48"
                :tip="$t('Head View')"
                :url="
                  ActiveCube == '5'
                    ? '/image/home/ASixViews5.png'
                    : '/image/home/SixViews5.png'
                "
              ></tipIcom
            ></el-dropdown-item>
            <img class="custom-spacer" src="/image/home/Line .png" />
            <el-dropdown-item command="6">
              <tipIcom
                iconClass="icon48"
                :tip="$t('Foot View')"
                :url="
                  ActiveCube == '6'
                    ? '/image/home/ASixViews6.png'
                    : '/image/home/SixViews6.png'
                "
              ></tipIcom
            ></el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <tipIcom
        iconClass="icon48"
        :tip="$t('Reset View')"
        url="/image/home/resetView.png"
        @click="resetView"
      ></tipIcom>
      <tipIcom
        iconClass="icon48"
        :tip="$t('Switch True Color')"
        :url="
          isGrayScale
            ? '/image/home/DisableRendering.png'
            : '/image/home/EnableRendering.png'
        "
        @click="toggleGrayScale"
      ></tipIcom>
    </div>
  </div>
</template>

<script setup>
import {
  Scene,
  OrthographicCamera,
  WebGLRenderer,
  AmbientLight,
  PointLight,
  MeshStandardMaterial,
  Mesh,
  Box3,
  Color,
  Vector3,
  AxesHelper,
  DoubleSide,
  Group,
} from "three";
import { ArcballControls } from "three/examples/jsm/controls/ArcballControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { PublicStore } from "@/tools/pinia/store";
import { reactive, ref } from "vue";
const props = defineProps({
  modelUrlArr: {
    type: Array,
    default: {},
  },
});

let publicData = PublicStore();
// 禁止冒泡和去除鼠标右键默认菜单
function handleMouseDown(event) {
  event.stopPropagation();
  event.preventDefault();
}
// 滑块调整逻辑处理
let sliderData = reactive([
  {
    group: "base",
    pos: "low",
    type: "maxilla",
    iconClass: "maxillaIcon",
    sliderValue: 1,
    eyeState: false,
    mesh: null,
  },
  {
    group: "base",
    pos: "up",
    type: "mandible",
    iconClass: "mandibleIcon",
    sliderValue: 1,
    eyeState: false,
    mesh: null,
  },
  {
    group: "implant",
    pos: "low",
    type: "maxillaryImplant",
    iconClass: "maxillaryImplantIcon",
    sliderValue: 1,
    eyeState: false,
    mesh: null,
  },
  {
    group: "implant",
    pos: "up",
    type: "mandibularImplant",
    iconClass: "mandibularImplantIcon",
    sliderValue: 1,
    eyeState: false,
    mesh: null,
  },
  {
    group: "bite",
    pos: "1",
    type: "leftOcclusion",
    iconClass: "leftOcclusionIcon",
    sliderValue: 1,
    eyeState: false,
    mesh: null,
  },
  {
    group: "bite",
    pos: "2",
    type: "RightOcclusion",
    iconClass: "RightOcclusionIcon",
    sliderValue: 1,
    eyeState: false,
    mesh: null,
  },
  {
    group: "orthodontic",
    pos: "low",
    type: "UpperJawPreparation",
    iconClass: "UpperJawPreparationIcon",
    sliderValue: 1,
    eyeState: false,
    mesh: null,
  },
  {
    group: "orthodontic",
    pos: "up",
    type: "LowerJawPreparation",
    iconClass: "LowerJawPreparationIcon",
    sliderValue: 1,
    eyeState: false,
    mesh: null,
  },
]);
function showModelPart(item) {
  if (item.eyeState) {
    item.eyeState = false;
    item.mesh.visible = false;
  } else {
    switch (item.group) {
      case "base":
        sliderData.forEach((item) => {
          if (item.group == "bite" && item.pos == "1" && item.mesh != null) {
            item.mesh.visible = false;
            item.eyeState = false;
          } else if (
            item.group == "bite" &&
            item.pos == "2" &&
            item.mesh != null
          ) {
            item.mesh.visible = false;
            item.eyeState = false;
          }
        });
        break;
      case "implant":
        sliderData.forEach((item) => {
          if (item.group == "bite" && item.pos == "1" && item.mesh != null) {
            item.mesh.visible = false;
            item.eyeState = false;
          } else if (
            item.group == "bite" &&
            item.pos == "2" &&
            item.mesh != null
          ) {
            item.mesh.visible = false;
            item.eyeState = false;
          } else if (item.group == "orthodontic" && item.mesh != null) {
            item.mesh.visible = false;
            item.eyeState = false;
          }
        });
        break;
      case "bite":
        if (item.group == "bite" && item.pos == "1" && item.mesh != null) {
          sliderData.forEach((item) => {
            if (item.pos != "1" && item.mesh != null) {
              item.mesh.visible = false;
              item.eyeState = false;
            }
          });
        } else if (
          item.group == "bite" &&
          item.pos == "2" &&
          item.mesh != null
        ) {
          sliderData.forEach((item) => {
            if (item.pos != "2" && item.mesh != null) {
              item.mesh.visible = false;
              item.eyeState = false;
            }
          });
        }
        break;
      case "orthodontic":
        sliderData.forEach((item) => {
          if (item.group == "bite" && item.pos == "1" && item.mesh != null) {
            item.mesh.visible = false;
            item.eyeState = false;
          } else if (
            item.group == "bite" &&
            item.pos == "2" &&
            item.mesh != null
          ) {
            item.mesh.visible = false;
            item.eyeState = false;
          } else if (item.group == "implant") {
            item.mesh.visible = false;
            item.eyeState = false;
          }
        });
        break;
    }
    item.eyeState = true;
    item.mesh.visible = true;
  }
  render();
}
function changeSliderValue(item) {
  item.mesh.material.opacity = item.sliderValue;
  render();
}
// canvas场景
let scene = null;
let camera = null;
let frustumSize = 120; //可视区域的大小
let renderer = null;
let controls = null;
let pointLight = null;
let ambientLight = null;
let groupMap = {};
// 初始化场景、相机、灯光等
onMounted(() => {
  nextTick(() => {
    const element = document.getElementById("ImplantPreView3D");
    const parentElement = element.parentElement;
    const width = parentElement.offsetWidth;
    const height = parentElement.offsetHeight;
    // 定义正交相机参数
    const aspectRatio = width / height;
    // 创建主场景
    scene = new Scene();
    camera = new OrthographicCamera(
      (-frustumSize * aspectRatio) / 2, // left
      (frustumSize * aspectRatio) / 2, // right
      frustumSize / 2, // top
      -frustumSize / 2, // bottom
      0.1, // near
      1000 // far
    );
    camera.position.set(0, 0, 50); // 调整位置以适应
    camera.lookAt(0, 0, 0);

    ambientLight = new AmbientLight(0xffffff, 1);
    pointLight = new PointLight(0xffffff, 0.8, 100);
    pointLight.castShadow = true;

    // 设置点光源的位置
    pointLight.position.set(
      camera.position.x,
      camera.position.y,
      camera.position.z
    );
    scene.add(pointLight);
    scene.add(ambientLight);

    renderer = new WebGLRenderer({
      antialias: true,
      powerPreference: "high-performance",
    });
    renderer.setSize(width, height);
    renderer.setClearColor(0xf0f3f6, 1);
    renderer.shadowMap.enabled = true;
    renderer.setPixelRatio(window.devicePixelRatio);

    controls = new ArcballControls(camera, renderer.domElement);
    // 设置鼠标操作
    controls.unsetMouseAction(2, null);
    // controls.unsetMouseAction("WHEEL", null);
    controls.minZoom = 0.4;
    controls.maxZoom = 2;

    let isDragging = false;
    let downControl = false;
    // 监听 ArcballControls 的 change 事件
    controls.addEventListener("change", (event) => {
      renderer.domElement.classList.remove("cursorMove");
      renderer.domElement.classList.remove("cursorFlip");
      // 在 change 事件中根据鼠标状态来判断操作类型
      if (isDragging && !downControl) {
        // 正在旋转
        renderer.domElement.classList.add("cursorFlip");
      } else if (downControl && isDragging) {
        // 默认状态
        renderer.domElement.classList.add("cursorMove");
      }

      // 更新光源位置并渲染场景
      pointLight.position.copy(camera.position);
      // camera.lookAt(0, 0, 0);
      render();
    });

    renderer.domElement.tabIndex = 0;
    renderer.domElement.focus();
    renderer.domElement.style.outline = "none";
    // 监听鼠标按下事件
    renderer.domElement.addEventListener("mousedown", (event) => {
      isDragging = true;
    });

    // 监听鼠标抬起事件
    renderer.domElement.addEventListener("mouseup", (event) => {
      isDragging = false;
      // 默认状态
      renderer.domElement.classList.remove("cursorMove");
      renderer.domElement.classList.remove("cursorFlip");
      isDragging = false;
      downControl = false;
    });
    renderer.domElement.addEventListener("keydown", (event) => {
      if (event.key == "Control") {
        downControl = true;
      }
    });
    renderer.domElement.addEventListener("keyup", (event) => {
      if (event.key == "Control") {
        downControl = false;
      }
    });

    // 监听鼠标移动事件
    renderer.domElement.addEventListener("mousemove", (event) => {
      if (isDragging) {
        isDragging = true;
      }
    });

    let timeoutId;
    renderer.domElement.addEventListener("wheel", (event) => {
      clearTimeout(timeoutId); // 清除之前的定时器

      renderer.domElement.classList.add("cursorZoom-in");
      timeoutId = setTimeout(() => {
        renderer.domElement.classList.remove("cursorZoom-in");
      }, 200);
    });

    // 创建坐标轴辅助线
    // const axesHelper = new AxesHelper(5); // 参数为坐标轴长度
    // scene.add(axesHelper);

    element.appendChild(renderer.domElement);
    newGroup();
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeRender);
});
function newGroup() {
  // 创建组
  groupMap = new Group();
  // 给组取一个名字
  let modelPromises = props.modelUrlArr.map((item) => {
    let lastDotIndex = item.src.lastIndexOf(".");
    let afterDot = item.src.slice(lastDotIndex + 1);
    switch (afterDot) {
      case "ply":
        return plyModel(item); // 返回 Promise
      case "stl":
        return stlModel(item); // 返回 Promise
      default:
        return Promise.resolve(); // 处理未知格式
    }
  });
  // 使用 Promise.all 等待所有模型加载完成
  Promise.all(modelPromises).then(() => {
    // 遍历 Group 中的所有对象
    groupMap.children.forEach((object) => {
      let index = sliderData.findIndex(
        (item) => item.group == object.kind && item.pos == object.name
      );

      if (index != -1) {
        sliderData[index].mesh = object;
      }
    });
    sliderData.forEach((item) => {
      if (item.mesh != null) {
        item.mesh.visible = false;
      }
      if (item.group == "base" && item.mesh != null) {
        item.mesh.visible = true;
        item.eyeState = true;
      }
    });
    render();
    publicData.changeLoadingShow(false);
    // 所有模型加载完成后执行渲染逻辑
    console.log("所有模型加载完成，开始渲染");
  });
  scene.add(groupMap);
}

function stlModel(item) {
  const loader = new STLLoader();
  return loadModel(import.meta.env.VITE_APP_OSSURL + item.src).then(
    (arrayBuffer) => {
      const stl = loader.parse(arrayBuffer);
      // 解析 STL 模型文件
      const material = new MeshStandardMaterial({
        //材质定义
        roughness: 0.3,
        side: DoubleSide,
        vertexColors: true, // 使用顶点颜色
        transparent: true, // 启用透明度
        opacity: 1.0, // 初始设置为完全不透明
        emissive: 0xffffff, // 设置自发光颜色为白色
        emissiveIntensity: 0.2, // 设置自发光强度
      });
      const stlMesh = new Mesh(stl, material);
      stlMesh.name = item.pos; // 设置名字
      stlMesh.kind = item.kind; // 设置名字
      stlMesh.geometry.computeVertexNormals();
      groupMap.add(stlMesh);
    }
  );
}

// 解析 PLY 模型文件
function plyModel(item) {
  const loader = new PLYLoader();
  return loadModel(import.meta.env.VITE_APP_OSSURL + item.src).then(
    (arrayBuffer) => {
      const ply = loader.parse(arrayBuffer);
      // 解析 STL 模型文件
      const material = new MeshStandardMaterial({
        //材质定义
        roughness: 0.3,
        side: DoubleSide,
        vertexColors: true, // 使用顶点颜色
        transparent: true, // 启用透明度
        opacity: 1.0, // 初始设置为完全不透明.
        emissive: 0xffffff, // 设置自发光颜色为白色
        emissiveIntensity: 0.2, // 设置自发光强度
      });
      const mesh = new Mesh(ply, material);
      // mesh.scale.set(0.001, 0.001, 0.001);
      // mesh.geometry.computeVertexNormals();

      mesh.name = item.pos; // 设置名字
      mesh.kind = item.kind; // 设置名字
      groupMap.add(mesh);
    }
  );
}

// 计算模型边界框并调整相机位置
function fitCameraToObject(object) {
  const boundingBox = new Box3().setFromObject(object);
  const center = boundingBox.getCenter(new Vector3());
  const size = boundingBox.getSize(new Vector3());

  const maxSize = Math.max(
    boundingBox.max.x,
    boundingBox.max.y,
    boundingBox.max.z
  );
  const cameraDistance = maxSize * 2;
  camera.position.copy(center).add(new Vector3(0, 0, cameraDistance));
  camera.lookAt(center);

  controls.target.copy(center); // 更新 ArcballControls 的目标
  controls.update(); // 更新控制器

  pointLight.position.set(
    camera.position.x,
    camera.position.y,
    camera.position.z
  );
  render();
}

// 渲染更新
function render() {
  renderer.setViewport(
    0,
    0,
    renderer.domElement.width,
    renderer.domElement.height
  );
  renderer.setScissor(
    0,
    0,
    renderer.domElement.width,
    renderer.domElement.height
  );
  // renderer.setScissorTest(false);
  renderer.render(scene, camera);
}

// 调整窗口大小时更新渲染视图
function resizeRender() {
  const element = document.getElementById("ImplantPreView3D");
  const parentElement = element.parentElement;
  const width = parentElement.offsetWidth;
  const height = parentElement.offsetHeight;
  // 计算新的纵横比
  const aspectRatio = width / height;

  // 更新相机参数
  camera.left = (-frustumSize * aspectRatio) / 2;
  camera.right = (frustumSize * aspectRatio) / 2;
  camera.top = frustumSize / 2;
  camera.bottom = -frustumSize / 2;

  renderer.setSize(width, height);
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
  render(); // 确保在调整大小后重新渲染场景
}

window.addEventListener("resize", resizeRender);

// 工具函数
// 重置视图
function resetView() {
  ActiveCube.value = "";
  controls.reset();
}

// 真彩模式
let isGrayScale = ref(false); // 用于跟踪灰度模式的状态
function toggleGrayScale() {
  isGrayScale.value = !isGrayScale.value;
  if (isGrayScale.value) {
    scene.traverse((object) => {
      if (object.isMesh) {
        // 确保对象有材质并且材质支持顶点颜色
        if (object.material && object.material.vertexColors !== undefined) {
          object.material.vertexColors = false; // 禁用顶点颜色
          object.material.color.set(0x808080); // 可选：设置为白色或其他颜色
          object.userData.originalColor &&
            object.material.color.copy(object.userData.originalColor); // 恢复原来的颜色
          object.material.needsUpdate = true; // 需要更新材质
        }
      }
    });
  } else {
    scene.traverse((object) => {
      if (object.isMesh) {
        if (object.material && object.material.vertexColors !== undefined) {
          object.material.vertexColors = true; // 启用顶点颜色
          if (object.userData.originalColor) {
            object.material.color.copy(object.userData.originalColor);
          }
          const newMaterial = new MeshStandardMaterial({
            roughness: 0.3,
            side: DoubleSide,
            vertexColors: true, // 使用顶点颜色
            transparent: true, // 启用透明度
            opacity: 1.0, // 初始设置为完全不透明
            emissive: 0xffffff, // 设置自发光颜色为白色
            emissiveIntensity: 0.2, // 设置自发光强度
          });
          // 替换 Mesh 的材质
          object.material = newMaterial;
          object.geometry.computeVertexNormals();
          object.material.needsUpdate = true; // 更新材质
        }
      }
    });
  }
  render(); // 切换灰度模式后重新渲染场景
}

// 观察面选择
let ActiveCube = ref("");
function handleCommand(value) {
  switch (value) {
    case "1":
      controls.reset();
      camera.position.set(0, 0, 50); // 调整位置以适应
      // 设置点光源的位置
      pointLight.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z
      );
      controls.update();
      render();
      break;
    case "2":
      controls.reset();
      camera.position.set(0, 0, -50); // 调整位置以适应
      // 设置点光源的位置
      pointLight.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z
      );
      controls.update();
      render();
      break;
    case "3":
      controls.reset();
      camera.position.set(50, 0, 0); // 调整位置以适应
      // 设置点光源的位置
      pointLight.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z
      );
      controls.update();
      render();
      break;
    case "4":
      controls.reset();
      camera.position.set(-50, 0, 0); // 调整位置以适应
      // 设置点光源的位置
      pointLight.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z
      );
      controls.update();
      render();
      break;
    case "5":
      controls.reset();
      camera.position.set(0, 50, 0); // 调整位置以适应
      // 设置点光源的位置
      pointLight.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z
      );
      controls.update();
      render();
      break;
    case "6":
      controls.reset();
      camera.position.set(0, -50, 0); // 调整位置以适应
      // 设置点光源的位置
      pointLight.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z
      );
      controls.update();
      render();
      break;
  }
  ActiveCube.value = value;
}

// 全屏显示||恢复默认窗口大小
let fullScreenShow = ref(false);
function fullScreen() {
  if (!fullScreenShow.value) {
    const modelPreviewElement = document.querySelector(".ModelPreview");
    modelPreviewElement.classList.replace("ModelPreview", "FullModelPreview");
    const StlDialogContentElement = document.querySelector(".StlDialogContent");
    StlDialogContentElement.classList.replace(
      "StlDialogContent",
      "FullStlDialogContent"
    );
    const viewPopperElement = document.querySelector(".viewPopper");
    viewPopperElement.classList.replace("viewPopper", "FullviewPopper");
    fullScreenShow.value = true;
  } else {
    const modelPreviewElement = document.querySelector(".FullModelPreview");
    modelPreviewElement.classList.replace("FullModelPreview", "ModelPreview");
    const StlDialogContentElement = document.querySelector(
      ".FullStlDialogContent"
    );
    StlDialogContentElement.classList.replace(
      "FullStlDialogContent",
      "StlDialogContent"
    );
    const viewPopperElement = document.querySelector(".FullviewPopper");
    viewPopperElement.classList.replace("FullviewPopper", "viewPopper");
    fullScreenShow.value = false;
  }
  resizeRender();
  // background: #6F9C2980;
}
</script>
<style lang="scss">
.StlDialogContent {
  width: 100%;
  height: 800px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.FullStlDialogContent {
  width: 100%;
  height: 100vh;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.viewPopper {
  position: absolute !important;
  width: 344px;
  height: 64px !important;
  top: 150px !important;
  left: 1200px !important;
  box-shadow: 3px 3px 12px 0px #13141540;
  border-radius: 8px;
  .el-dropdown__list {
    height: 64px !important;
  }
  .el-dropdown-menu {
    width: 100%;
    height: 64px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    padding: 0 8px 0 8px !important;
    box-sizing: border-box;
    .el-dropdown-menu__item {
      height: 48px;
      padding: 0 !important;
    }
  }
  .el-popper__arrow {
    display: none;
  }
}
.FullviewPopper {
  position: absolute !important;
  width: 344px;
  height: 64px !important;
  top: 92px !important;
  left: 1440px !important;
  box-shadow: 3px 3px 12px 0px #13141540;
  border-radius: 8px;
  .el-dropdown__list {
    height: 64px !important;
  }
  .el-dropdown-menu {
    width: 100%;
    height: 64px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    padding: 0 8px 0 8px !important;
    box-sizing: border-box;
    .el-dropdown-menu__item {
      height: 48px;
      padding: 0 !important;
    }
  }
  .el-popper__arrow {
    display: none;
  }
}
// 鼠标样式
.cursorMove {
  cursor: url("/image/home/Move.png") 0 0, auto !important;
}
.cursorZoom-in {
  cursor: url("/image/home/zoom-in.png") 0 0, auto !important;
}
.cursorFlip {
  cursor: url("/image/home/flip.png") 0 0, auto !important;
}
</style>
<style scoped lang="scss">
#ImplantPreView3D {
  position: relative;
  width: 100%;
  height: 100%;
}
.ModelAdjustment {
  position: absolute;
  top: 32px;
  left: 32px;
  width: 252px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sliderbox {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      width: 140px;
    }
    .el-slider {
      .el-slider__runway {
        height: 4px;
      }
      .el-slider__bar {
        height: 4px;
      }
    }

    .maxillaIcon {
      width: 48px;
      height: 48px;
      display: block;
      background: url("/image/home/maxilla.png");
      background-size: cover;
    }
    .mandibleIcon {
      width: 48px;
      height: 48px;
      display: block;
      background: url("/image/home/mandible.png");
      background-size: cover;
    }
    .maxillaryImplantIcon {
      width: 48px;
      height: 48px;
      display: block;
      background: url("/image/home/maxillaryImplant.png");
      background-size: cover;
    }
    .mandibularImplantIcon {
      width: 48px;
      height: 48px;
      display: block;
      background: url("/image/home/mandibularImplant.png");
      background-size: cover;
    }
    .leftOcclusionIcon {
      width: 48px;
      height: 48px;
      display: block;
      background: url("/image/home/leftOcclusion.png");
      background-size: cover;
    }
    .RightOcclusionIcon {
      width: 48px;
      height: 48px;
      display: block;
      background: url("/image/home/RightOcclusion.png");
      background-size: cover;
    }
    .UpperJawPreparationIcon {
      width: 48px;
      height: 48px;
      display: block;
      background: url("/image/home/UpperJawPreparation.png");
      background-size: cover;
    }
    .LowerJawPreparationIcon {
      width: 48px;
      height: 48px;
      display: block;
      background: url("/image/home/LowerJawPreparation.png");
      background-size: cover;
    }
    .eyeOn {
      width: 40px;
      height: 40px;
      display: block;
      background: url("/image/home/eyeOn.png");
      background-size: cover;
    }
    .eyeClose {
      width: 40px;
      height: 40px;
      display: block;
      background: url("/image/home/eyeClose.png");
      background-size: cover;
    }
  }
}
.fullScreenTool {
  position: absolute !important;
  top: 35px;
  right: 200px;
}
.toolbar {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  // gap: 10px;
  width: 176px;
  height: 64px;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 4px 4px 16px 0px #13141540;
  justify-content: space-around;
  button {
    background-color: #e0bd00;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  button:hover {
    background-color: #c0a000;
  }
}
</style>
