/*
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-07-18 09:32:24
 * @LastEditTime: 2024-09-20 14:05:32
 */

import axios from "axios";
import router from '@/router';
import NProgress from "nprogress";
import "nprogress/nprogress.css"
import { PublicStore } from "@/tools/pinia/store";
const instance = axios.create({
  baseURL: "",
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
    // "language": 0,
    // "AccessToken": "",
    // "RefreshToken": "",
  }
})
// 请求拦截
instance.interceptors.request.use(function (config) {
  let publicData = PublicStore()
  // 设置请求头
  if (publicData) {
    config.headers['AccessToken'] = publicData.AccessToken;
    config.headers['RefreshToken'] = publicData.RefreshToken;
    config.headers['language'] = publicData.language;
    config.headers['timeZone'] = publicData.timeZone;
  }
  NProgress.start();
  return config;
}, function (error) {
  NProgress.done();
  return Promise.reject(error);
});
// 响应拦截
instance.interceptors.response.use(function (response) {
  if (response.config.url != "/dental/base/refresh" && response.data.code == 20000) {//token已过期
    return instance.get("/dental/base/refresh").then(refreshResponse => {
      if (refreshResponse.data.code === 200) {
        let publicData = PublicStore()
        // 更新请求头并重试原请求
        publicData.changeAccessToken(refreshResponse.data.data.AccessToken)
        publicData.changeRefreshToken(refreshResponse.data.data.RefreshToken)
        response.config.headers['AccessToken'] = refreshResponse.data.data.AccessToken;
        response.config.headers['RefreshToken'] = refreshResponse.data.data.RefreshToken;
        if (publicData.WebSocketService!=null) {
          publicData.changeWebSocketService();
          publicData.WebSocketService.connect();
        }
        return instance(response.config); // 重试原请求
      } else {
        router.replace('/login'); // 刷新失败，重定向
        return Promise.reject(new Error('Token refresh failed'));
      }
    });
  }
  NProgress.done();
  return response;
}, function (error) {
  NProgress.done();
  return Promise.reject(error);
});
export default instance