<template>
  <div class="PreviewContent">
    <div class="PreviewPicture">
      <div class="PictureBox">
        <el-carousel
          @change="onSlideChange"
          :loop="false"
          indicator-position="none"
          :autoplay="false"
          arrow="always"
        >
          <el-carousel-item v-for="item in srcList" :key="item.id" >
            <img
              style="object-fit: contain; width: 100%; height: 100% "
              :src="`https://cambridgeweb.oss-accelerate.aliyuncs.com/${encodeURIComponent(
                item.url
              )}`"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="PictureTotal">
      {{ current + 1 + "/" + srcList.length }}
    </div>
  </div>
</template>
    
<script setup>
const props = defineProps({
  currentIndex: {
    type: Number,
    default: 0,
  },
  srcList: {
    type: Array,
    default: [],
  },
});
let current=ref(props.currentIndex)
function onSlideChange(value) {
    current.value = value; // 更新当前索引
    
}
</script>
    
<style scoped lang='scss'>
 .PreviewContent {
    height: 800px;
    border-radius: 8px;
    overflow: hidden;
    .PreviewPicture {
      height: calc(100% - 50px);
      display: flex !important;
      align-items: center;
      overflow: hidden;
      .PictureBox {
        width: 100%;
        height: 100%;
        .swiper {
          width: 100%;
          height: 100%;
        }
        .el-carousel {
          height: 100%;
        }
        :deep(.el-carousel__container) {
          height: 100% !important;
        }
      }
      .carousel-item {
        float: left;
        width: 100%;
        height: 100%;
      }
    }
    .PictureTotal {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
