<template>
  <el-dialog
    draggable
    lock-scroll
    v-model="sendMailDialogShow"
    :modal="false"
    :close-on-click-modal="false"
    top="20vh"
    :show-close="false"
    class="secondLevelDialog"
    destroy-on-close
    append-to-body
    @close="sendEmailFormRef.resetFields()"
  >
    <template #header>
      <div class="my-header">
        <p>
          {{ t("You invited workshop will receive an email.") }}<br />{{
            t("You can customize the following invitation content:")
          }}
        </p>
      </div>
    </template>

    <div class="sendEmailContent">
      <el-row type="flex" align="middle" justify="center">
        <el-col :span="20">
          <el-form
            label-position="left"
            :model="sendEmailForm"
            ref="sendEmailFormRef"
            class="sendEmailForm"
            :rules="sendEmailFormRules"
          >
            <el-form-item prop="laboratory">
              <template #label>
                <span class="custom-label" v-textTootip:auto>{{
                  t("Workshop Notes")
                }}</span>
                <span class="required" style="color: #ff0000">*</span>
              </template>
              <el-input v-model="sendEmailForm.laboratory" />
            </el-form-item>
            <el-form-item prop="Email">
              <template #label>
                <span class="custom-label" v-textTootip:auto>{{
                  t("Email")
                }}</span>
                <span class="required" style="color: #ff0000">*</span>
              </template>
              <el-input v-model="sendEmailForm.Email" />
            </el-form-item>
            <el-form-item prop="content">
              <template #label>
                <span class="custom-label" v-textTootip:auto>{{
                  t("Content")
                }}</span>
                <span class="required" style="color: #ff0000">*</span>
              </template>
              <el-input
                v-model="sendEmailForm.content"
                type="textarea"
                maxlength="500"
                show-word-limit
                :rows="7"
                resize="none"
              />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <el-row type="flex" align="middle" justify="center">
        <el-col :span="5" :offset="6">
          <el-button @click="closeSendMailShow"
            ><img src="/image/home/emailClose.png" />
            {{ t("Cancel") }}</el-button
          >
        </el-col>
        <el-col :span="5">
          <el-button
            type="primary"
            v-debounce:click="{ handler: sendEmail, delay: 300 }"
            ><img src="/image/home/send.png" />{{ t("Send") }}</el-button
          >
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>
    
<script setup>
const { t } = useI18n();
const props = defineProps({
  sendMailShow: {
    type: Boolean,
    default: false,
  },
});
let sendMailDialogShow=ref(false)
watch(
  () => props.sendMailShow,
  (value) => {
    sendMailDialogShow.value = value;
  }
);
const sendEmailForm = reactive({
  laboratory: "",
  Email: "",
  content: "",
});

let emit = defineEmits(["closeSendMailShow","openInviteList"]);
function closeSendMailShow() {
  emit("closeSendMailShow");
}
let sendEmailFormRef = ref(null);
function sendEmail() {
  sendEmailFormRef.value.validate((valid) => {
    if (valid) {
      let data = {
        RemarkToPartner: sendEmailForm.laboratory,
        Email: sendEmailForm.Email,
        Content: sendEmailForm.content,
      };
      postRequest("/dental/user/addPartner", data).then((res) => {
        if (res.code == 200) {
          ElMessage({
            message: res.message,
            type: "success",
          });
          emit("openInviteList");
          emit("closeSendMailShow");
          return;
        }
        ElMessage({
          message: res.message,
          type: "error",
        });
      });
    }
  });
}


const verifyEmail = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter your email address")));
  } else {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      callback(new Error(t("Please input a valid email address")));
    }
    callback();
  }
};
const verifyLaboratory = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please Enter Workshop")));
  } else {
    callback();
  }
};
const verifyContent = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please Enter Email Content")));
  } else {
    callback();
  }
};
const sendEmailFormRules = reactive({
  laboratory: [{ validator: verifyLaboratory, trigger: "blur" }],
  Email: [{ validator: verifyEmail, trigger: "blur" }],
  content: [{ validator: verifyContent, trigger: "blur" }],
});

</script>
    
<style  lang='scss'>
// 邀请发送邮件二级dialog
.secondLevelDialog {
  width: 800px;
  padding: 0;
  box-shadow: 4px 4px 16px 0px #13141540;
  border-radius: 8px;
  border: 1px solid #ccd0d5;
  .el-dialog__header {
    border: 0 !important;
    p {
      font-size: 16px;
      color: #6f9c29;
      margin: 32px 0 0 64px;
    }
  }
  .sendEmailContent {
    margin: 30px 0 0px 0;
    .sendEmailForm {
      .el-input__inner {
        height: 40px;
      }
      .el-form-item__label {
        width: 174px;
        line-height: 40px;
        height: 40px;
        font-weight: 600;
      }
    }
  }
  .el-dialog__footer {
    height: 110px;
    .el-button {
      width: 144px;
      height: 48px;
      img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
    }
  }
  .el-form-item {
    color: #494c4f;
  }
}
</style>
