import { openDB } from 'idb'

const DB_NAME = 'dentalCloud'
const STORE_NAME = 'modelData'
const CACHE_EXPIRY = 1 * 24 * 60 * 60 * 1000 // 缓存有效期，设置为一天，不经常更新的数据，缓存有效期可以设置的长一点

const getDB = async () => {
    return openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME)
            }
        },
    })
}

const setItem = async (key, value) => {
    try {
        const db = await getDB()
        const data = { value, timestamp: Date.now() }
        await db.put(STORE_NAME, data, key)
        console.log(`存储数据使用的密钥：${key}`)
    } catch (error) {
        console.log('数据存储失败！', error)
    }
}

const getItem = async (key) => {
    try {
        const db = await getDB()
        const data = await db.get(STORE_NAME, key)
        if (data) {
            const isExpired = Date.now() - data.timestamp > CACHE_EXPIRY
            if (isExpired) {
                await deleteItem(key)
                return null
            }
            return data.value
        }
        console.log(`密钥匹配数据：${key}`)
        return null
    } catch (error) {
        console.log(error)
    }
}

const deleteItem = async (key) => {
    try {
        const db = await getDB()
        await db.delete(STORE_NAME, key)
        console.log(`删除密钥：${key}`)
    } catch (error) {
        console.log('删除密钥失败！', error)
    }
}

const clearStore = async () => {
    try {
        const db = await getDB()
        await db.clear(STORE_NAME)
        console.log('清除所有数据成功！')
    } catch (error) {
        console.log('清除所有数据失败！', error)
    }
}

/**
 * @description: 采用强制缓存将行政区数据缓存
 * @param {*} node
 * @param {*} resolve
 * @return {*}
 */      

  
export { openDB, setItem, getItem, deleteItem, clearStore}

