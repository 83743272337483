<template>
  <div class="MPRmanyLayout" id="default-viewMPR">
    <div
      v-for="(item, index) in View3dCanvas"
      :key="item.Num"
      class="sequenceView viewport"
      :class="item.class"
      :data-inx="item.Num"
      :data-ord="index"
      :id="'default-v' + item.Num"
    >
      <div class="title">
        <i class="viewIcon" :style="{ background: item.viewIcon }"></i>
        <div class="toolRight">
          <btnImg
            v-for="es in screenshot"
            :boxSizeClass="'btnImg28'"
            :active="es.Active"
            :imgSrc="es.imgSrc"
            :imgSrcHover="es.imgSrcHover"
            :imgSrcActive="es.imgSrcActive"
            :key="es.name"
            :independent="es.independent"
            :btnName="es.label"
            @click="WindowScreenshot(item.OrthoView)"
          ></btnImg>
          <btnImg
            v-for="es in zoom"
            :key="es.name"
            :class="es.Class"
            :boxSizeClass="'btnImg28'"
            :active="es.Active"
            :imgSrc="es.imgSrc"
            :imgSrcHover="es.imgSrcHover"
            :imgSrcActive="es.imgSrcActive"
            :independent="es.independent"
            :btnName="es.label"
            @click="viewZoomFuc(item.Num, es.Class)"
          ></btnImg>
          <el-select
            v-if="item.ifThicknessVal"
            :title="$t('LayerThicknessRegulation')"
            popper-class="selectStyle"
            class="thickness"
            v-model="item.thicknessVal"
            :popper-append-to-body="false"
            @input="thicknessSelectVal(item.OrthoView, item.thicknessVal)"
            :placeholder="''"
          >
            <el-option
              v-for="label in item.thicknessData"
              :key="label"
              :label="label"
              :value="label"
            >
              <span style="float: left">{{ label }}</span>
              <i
                style="float: right; font-size: 13px"
                v-if="item.thicknessVal == label"
                class="tickIcon"
              ></i>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="view hv-mid">
        <div
          :id="item.OrthoView"
          :ref="item.OrthoView"
          :width="item.width"
          :height="item.height"
          :style="'width:' + item.width + 'px;height:' + item.height + 'px'"
          ondragstart="return false;"
          @mousewheel="MouseWheel($event, item)"
          @mouseenter="OnMouseEnter()"
          @mouseleave="OnMouseLeave()"
          @mousemove="OnMouseMove($event, item)"
          @mousedown="OnMouseDown($event, item, View3dCanvas)"
          @dblclick="OnDblclick($event, item)"
        ></div>
        <div class="scroll" v-show="item.showScroll" v-if="item.ifScroll">
          <el-slider
            v-model="item.scroll"
            vertical
            @input="onSliderval(item)"
            @mousedown="scrollBarMousedown"
            :show-tooltip="false"
            :min="item.min"
            :max="item.max"
          ></el-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { basemixin } from "./basemixin.js";
import btnImg from "@/components/common/btnImg.vue";
const { t } = useI18n();
let {
  initWebGl,
  dec,
  mousePos,
  getButtonShift,
  Send,
  onSendTimer,
  setViewSize,
  onHide,
  onShow,
  MouseWheel,
  OnMouseMove,
  OnMouseDown,
  OnDblclick,
} = basemixin();
let View3dCanvas = reactive([
  {
    Num: 1, //标识
    OrthoView: "OrthoView0", //窗口的class类名
    scroll: 0, //滚动条初始值
    showScroll: false, //滚动条是否显示与隐藏
    ifScroll: true, //判断这个窗口是否需要滚动条
    min: -50, //滚动条最大值
    max: 50, //滚动条最小值
    step: 1, //滚动条步进值
    ifThicknessVal: true, //判断是否渲染select厚度选择
    thicknessVal: "", //厚度select选择
    thicknessData: [], //厚度选择select的选项数据
    viewIcon: "#FFFF00", //视口标识图标颜色
    class: "col-md-6",
    width: 416, //宽
    height: 416, //高
    element: null, //canvas的dom实例
  },
  {
    Num: 2,
    OrthoView: "OrthoView1",
    scroll: 0,
    showScroll: false,
    ifScroll: true,
    min: -50,
    max: 50,
    step: 1,
    ifThicknessVal: true,
    thicknessVal: "",
    thicknessData: [],
    viewIcon: "#C9D2C9",
    class: "col-md-6",
    width: 416, //宽
    height: 416, //高
    element: null, //canvas的dom实例
  },
  {
    Num: 3,
    OrthoView: "OrthoView2",
    scroll: 0,
    showScroll: false,
    ifScroll: true,
    min: -50,
    max: 50,
    step: 1,
    ifThicknessVal: true,
    thicknessVal: "",
    thicknessData: [],
    viewIcon: "#312E8D",
    class: "col-md-6",
    width: 416, //宽
    height: 416, //高
    element: null, //canvas的dom实例
  },
  {
    Num: 4,
    OrthoView: "OrthoView3D",
    scroll: 0,
    showScroll: false,
    ifScroll: false,
    min: -50,
    max: 50,
    step: 1,
    ifThicknessVal: false,
    thicknessVal: "",
    thicknessData: [],
    viewIcon: "",
    class: "col-md-6",
    width: 416, //宽
    height: 416, //高
    element: null, //canvas的dom实例
  },
]);
let dftLayout = reactive({
  1: [1, 2, 3, 4],
  2: [2, 1, 4, 3],
  3: [3, 1, 2, 4],
  4: [4, 1, 2, 3],
});
// let shift = ref(null);
// let currentCanvas = ref(""); //鼠标按下时canvas所在的
let mouseIsDown = ref(false);
// 鼠标类型
let curtype = ref(null);
// 滚动条触发开关
let scrollBarDown = ref(false);
let sendTimer = ref(null); //定时器
let viewPortDifference = ref(
  "4-0,0,1,0.5,2,1,1&0.5&1,2&0.5&1-0,0.5,1,1,2,1,3&0.5&1,4&0.5&1"
); //窗口差异布局记录
let screenshot = reactive([
  {
    label: t("screenshot"),
    name: "screenshot",
    Active: false,
    independent: true,
    imgSrc: "/image/windowCanvas/screenshot.png",
    imgSrcActive: "/image/windowCanvas/Ascreenshot.png",
    imgSrcHover: "/image/windowCanvas/Hscreenshot.png",
  },
]);
let zoom = reactive([
  {
    label: t("zoom"),
    name: "zoom",
    Class: "zoom",
    Active: false,
    independent: true,
    imgSrc: "/image/windowCanvas/windowEnlargement.png",
    imgSrcActive: "/image/windowCanvas/AwindowEnlargement.png",
    imgSrcHover: "/image/windowCanvas/HwindowEnlargement.png",
  },
  {
    label: t("zoomOut"),
    name: "zoomOut",
    Class: `zoom-out hide`,
    Active: false,
    independent: true,
    imgSrc: "/image/windowCanvas/windowReduction.png",
    imgSrcActive: "/image/windowCanvas/AwindowReduction.png",
    imgSrcHover: "/image/windowCanvas/HwindowReduction.png",
  },
]);

let isFirstTime = ref(true); //首次进入判断
let OldDentalPatientInfo = ref({});
const props = defineProps({
  defaultAtive: {
    type: String,
  },
});
watch(
  () => props.defaultAtive,
  (newval, oldval) => {
    if (newval == "MPR") {
      nextTick(() => {
        if (isFirstTime.value) {
          isFirstTime.value = false;
          initWebGl(View3dCanvas);
          setViewSize(View3dCanvas);
          sendTimer = self.setInterval(onSendTimer, 1); // 如果延迟，可以延迟该时间
        } else {
          onShow(View3dCanvas);
        }
      });
    } else if (oldval == "MPR" && newval != "MPR") {
      onHide(View3dCanvas);
    }
  }
);

function OnMouseEnter(e) {}
function OnMouseLeave(e) {}

// 切记误删，这段代码是防止滑块事件的@input事件首次执行
function scrollBarMousedown() {
  scrollBarDown.value = true;
  window.onmouseup = () => {
    scrollBarDown.value = false;
    window.onmouseup = null;
  };
}
function onSliderval(item) {
  if (item.showScroll && scrollBarDown.value) {
    let wsData = {
      //   Msgcommand: comWebSocket3DScroll,
      planeoffset: -item.scroll,
    };
    this.$ws.sendWebsocketRequest(wsData);
  }
}
// function MouseUp3D() {
//   OnMouseUp("OrthoView3D");
// }
// function MouseUp0() {
//   OnMouseUp("OrthoView0");
// }
// function MouseUp1() {
//   OnMouseUp("OrthoView1");
// }
// function MouseUp2() {
//   OnMouseUp("OrthoView2");
// }
// 设置层厚
function thicknessSelectVal(name, thicknessData) {
  let id = NameSwitchId(name);
  let wsData = {
    // Msgcommand: comWebSocketSetMPRThickness,
    SliceThickness: Number(thicknessData),
    childid: id,
  };
  this.$ws.sendWebsocketRequest(wsData);
}
// 获取鼠标的坐标点s
function swappos(e) {
  let x = e.offsetX;
  let y = e.offsetY;
  return {
    x: parseInt(x),
    y: parseInt(y),
  };
}
//将canvas元素名转为id
// function NameSwitchId(name) {
//   let obj = View3dCanvas.find((item) => {
//     return item.OrthoView == name;
//   });
//   return obj.Num;
// }
// 单窗口截图功能
function WindowScreenshot(name) {
  if (name) {
    let canvas = document.getElementById(name).querySelector("canvas");
    // 将canvas转换成base64的png格式的url对象(图片数据)
    const imageData = canvas.toDataURL("image/webp", 0.7);
    let NofitIdentity = JSON.parse(
      sessionStorage.getItem("selectedStudy")
    ).NofitIdentity;
    let data = {
      nofitIdentity: NofitIdentity,
      baseData: imageData,
    };
    this.apiPost("admin/alreadycheck/savereportimage", data).then((res) => {
      if (res.code == 200) {
        ElMessage.success("截图成功");
      } else {
        ElMessage.error("截图失败");
      }
    });
  }
}

// 点击放大其他缩小
function viewZoomFuc(num, cur_class) {
  //同时改变前端的dom
  let layoutTp = cur_class == "zoom" ? "diff" : "avg";
  sortDomLst(num, layoutTp);
}
// 当放大缩小时盒子的位置也相对印改变
function exchange(id_1, id_2) {
  let parent = id_1.parentNode; // 获取父节点
  let newNode = document.createElement("div");

  parent.insertBefore(newNode, id_2); // 在 id_2 前插入新节点
  parent.insertBefore(id_2, id_1); // 在 id_1 前插入 id_2
  parent.insertBefore(id_1, newNode); // 在新节点前插入 id_1
  parent.removeChild(newNode); // 移除新节点
}
function sortDomLst(layoutInx, laytype) {
  let custOrd = dftLayout[layoutInx];
  let sortLen = document.querySelectorAll("#default-viewMPR .viewport").length;
  let dataOrd = "";
  if (laytype === "avg") {
    // 均化处理恢复自然顺序
    for (let i = 0; i < sortLen; i++) {
      let iObj = document.querySelector(`#default-viewMPR [data-ord="${i}"]`); // 序号代表的对象
      let curObj = document.querySelectorAll("#default-viewMPR .viewport")[i]; // 当前对象
      if (iObj && iObj !== curObj) {
        exchange(curObj, iObj);
      }
    }
    document
      .querySelectorAll("#default-viewMPR .viewport .zoom-out")
      .forEach((el) => {
        el.style.display = "none"; // 隐藏
        el.classList.remove("hide");
      });
    document
      .querySelectorAll("#default-viewMPR .viewport .zoom")
      .forEach((el) => {
        el.style.display = "inline-block"; // 显示为 inline-block
        el.classList.remove("hide");
      });
  } else if (laytype === "diff") {
    // 差异化处理(1大3小)
    document
      .querySelectorAll("#default-viewMPR .viewport .zoom-out")
      .forEach((el) => {
        el.style.display = "none"; // 隐藏
        el.classList.remove("hide");
      });
    document
      .querySelectorAll("#default-viewMPR .viewport .zoom")
      .forEach((el) => {
        el.style.display = "inline-block"; // 显示为 inline-block
        el.classList.remove("hide");
      });
    let layoutElement = document.querySelector(`#default-v${layoutInx}`);
    dataOrd = layoutElement.getAttribute("data-ord");
    if (layoutElement) {
      layoutElement.querySelectorAll(".zoom-out").forEach((el) => {
        el.style.display = "block"; // 显示
      });
      layoutElement.querySelectorAll(".zoom").forEach((el) => {
        el.style.display = "none"; // 隐藏
      });
    }
    for (let i = 0; i < sortLen; i++) {
      let myIx = custOrd[i] - 1;
      let iObj = document.querySelector(
        `#default-viewMPR [data-ord="${myIx}"]`
      ); // 序号代表的对象
      let curObj = document.querySelectorAll("#default-viewMPR .viewport")[i]; // 当前对象
      if (iObj && iObj !== curObj) {
        exchange(curObj, iObj);
      }
    }
  }

  // 均分视口数据发送给后端同步
  if (laytype == "avg") {
    setViewPortAvg();
  }
  // 差异视口数据发送给后端同步
  if (laytype == "diff") {
    setDiffViewPort(dataOrd, custOrd);
  }
}

// 差异视口
function setDiffViewPort(inx, custOrd) {
  let viewports = document.querySelectorAll("#default-viewMPR .viewport");

  viewports.forEach((viewport) => {
    viewport.classList.remove("col-md-9", "col-md-6", "col-md-3");
    viewport.classList.add("col-md-3");
  });

  let targetElement = document.querySelector(
    `#default-viewMPR [data-ord="${inx}"]`
  );
  if (targetElement) {
    targetElement.classList.remove("col-md-3");
    targetElement.classList.add("col-md-9");
  }

  // 同时要改变 canvas 画布的宽高
  setViewSize(View3dCanvas);
  // 通知后端改变画布局
  let paramvalue = `4-0,0,0.75,1,1,1,${custOrd[0]}&1&1-0.75,0,1,1,1,3,${custOrd[1]}&1&0.33,${custOrd[2]}&1&0.33,${custOrd[3]}&1&0.33`;
  let wsData = {
    // msgcommand: comWebSocket3DGridMaxMin,
    gridtype: 0,
    paramvalue: paramvalue,
  };

  viewPortDifference.vlaue = paramvalue;
  //   this.$ws.sendWebsocketRequest(wsData);
}

//均分视口
function setViewPortAvg() {
  let viewports = document.querySelectorAll("#default-viewMPR .viewport");
  viewports.forEach((viewport) => {
    viewport.classList.remove("col-md-9", "col-md-3");
    viewport.classList.add("col-md-6");
  });

  // 同时要改变 canvas 画布的宽高
  setViewSize(View3dCanvas);

  // 视口改变时内层 div 和 canvas 改变同时通知后台
  let paramvalue = "4-0,0,1,0.5,2,1,1&0.5&1,2&0.5&1-0,0.5,1,1,2,1,3&0.5&1,4&0.5&1";
  let wsData = {
    // msgcommand: comWebSocket3DGridMaxMin,
    gridtype: 0,
    paramvalue: paramvalue,
  };

  viewPortDifference.value = paramvalue;
  //   this.$ws.sendWebsocketRequest(wsData);
}
</script>

<style lang="scss">
.selectStyle {
  background: #545454;
  border: 0 !important;
  margin-top: -5px;
  .el-popper__arrow {
    display: none;
  }

  .el-select-dropdown__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999999;
    background: #545454;
    height: 30px;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 26px;
  }
  .is-selected {
    background: #545454;
    // color: #fff;
    font-weight: 100;
    //   // select的选中带勾效果
    .tickIcon {
      width: 14px;
      height: 14px;
      display: block;
      background: url("image/siderTool/勾.png");
    }
  }
  .el-select-dropdown__item:hover {
    color: #fff;
    background: #07c160;
    .tickIcon {
      width: 14px;
      height: 14px;
      display: block;
      background: url("image/siderTool/勾1.png");
    }
  }

  .selected {
    background: var(--el-color-primary) !important;
    color: #fff;
  }

  .el-select-dropdown__list {
    padding: 4px 0;
    min-height: 20px;
  }
}
.MPRmanyLayout {
  width: calc(100% - 228px) !important;
  height: 100% !important;
  background: #000;
  float: left;

  .sequenceView {
    display: inline-block;
    position: relative;

    .viewIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      width: 100%;
      .hide {
        display: none !important;
      }

      .toolRight {
        display: flex;
        align-items: center;

        .thickness {
          width: 98px;
          margin-right: 10px;
          .el-select__wrapper {
            min-height: 26px !important;
            background: #000000;
            box-shadow: 0 0 0 1px #545454 inset;
            .el-select__selected-item {
              color: #999999;
            }
          }

          .el-select__wrapper.is-focused {
            box-shadow: 0 0 0 1px #07c160 inset;
          }
        }
      }
    }

    .view {
      height: calc(100% - 40px);
      position: absolute;
      left: 0;
      top: 40px;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      overflow: hidden;
      .scroll {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        // background: pink;
        padding: 5px 0;
        box-sizing: border-box;
        .el-slider {
          height: 100%;
        }
        .el-slider__bar,
        .el-slider__runway {
          width: 5px;
          background: #4b4747;
        }

        .el-slider__button {
          width: 10px;
          height: 10px;
          border-radius: 5px !important;
          background: #07c160;

          & {
            transform: scale(1);
          }
        }
      }
    }
  }
}
</style>