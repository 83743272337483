<template>
  <div class="mian">
    <div class="topHeader">
      <div class="layoutMenu">
        <el-menu
          background-color="#151515"
          text-color="#999999"
          ref="menuDemo"
          active-text-color="#fff"
          mode="horizontal"
          :default-active="defaultAtive"
          @select="handleTopTaps"
        >
          <el-menu-item index="MPR">
            <span>{{ $t("MPR") }}</span>
          </el-menu-item>
          <el-menu-item index="Panorama">
            <span>{{ $t("3DPanoramicView") }}</span>
          </el-menu-item>
          <el-menu-item index="Planting">
            <span>{{ $t("plant") }}</span>
          </el-menu-item>
          <el-menu-item index="TMJ">
            <span>{{ $t("TMJ") }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <!-- 头部导航栏患者信息显示位置 -->
      <div class="PatientInformation">
        {{ $t("Patient information") }}:&nbsp;&nbsp;{{ PatientInformation }}
      </div>
      <div class="toolArea">
        <div class="userAvatar">
          <el-avatar :src="userAvatar.Avatar"></el-avatar>
          &nbsp;
          <span>{{ userAvatar.username }}</span>
        </div>
        <p class="vertical"></p>
        <btnImg
          v-for="item in homeSettingData"
          :key="item.name"
          :btnName="item.label"
          :boxSizeClass="'btnImg30'"
          :active="item.Active"
          :imgSrc="item.imgSrc"
          :imgSrcHover="item.imgSrcHover"
          :imgSrcActive="item.imgSrcActive"
          :independent="item.independent"
          @click="trigger(item)"
        >
        </btnImg>
      </div>
    </div>

    <div class="container">
      <div class="containerView">
        <div class="leftSidebar" :style="{ float: 'left' }">
          <LeftSider
            :defaultAtive="defaultAtive"
            :viewTool="viewTool"
            :MeasureTool="MeasureTool"
            :windowConfiguration="windowConfiguration"
          ></LeftSider>
        </div>
        <MPR
          ref="MPRComponent"
          v-show="defaultAtive == 'MPR'"
          :defaultAtive="defaultAtive"
        ></MPR>
        <Panorama
          ref="PanoramaComponent"
          v-show="defaultAtive == 'Panorama'"
          :defaultAtive="defaultAtive"
        ></Panorama>
        <Planting
          ref="PlantingComponent"
          v-show="defaultAtive == 'Planting'"
          :defaultAtive="defaultAtive"
        ></Planting>
        <TMJ
          ref="TMJComponent"
          v-show="defaultAtive == 'TMJ'"
          :defaultAtive="defaultAtive"
        ></TMJ>
      </div>
    </div>
  </div>
</template>

<script setup>
import MPR from "@/components/cbct/MPR.vue";
import Panorama from "@/components/cbct/Panorama.vue";
import Planting from "@/components/cbct/Planting.vue";
import TMJ from "@/components/cbct/TMJ.vue";
import LeftSider from "@/components/cbct/LeftSider.vue";

import { onMounted, reactive, watch } from "vue";
const { t } = useI18n();
let defaultAtive = ref(""); //进来默认第一个menu-item界面
// let canvasArr = reactive([]); //视图的canvas数据
// let alreadly = reactive([]); //点击视图记录
// let thumbnailItem = reactive({}); //点击的缩略图数据
// let layoutLoad = ref(false); //是否打开过layout，如果打开过不再重新发送窗口数据
let PatientInformation = ref(""); //患者信息
// let windowConfiguration = ref(""); //子级当前窗口布局的记录
let userAvatar = reactive({
  //头像与昵称
  Avatar: "/image/home/Medic.png",
  username: "医生名字",
});
const MPRComponent = ref(null);
const PanoramaComponent = ref(null);
let homeSettingData = reactive([
  {
    label: t("close"),
    name: "close",
    Active: false,
    independent: true,
    imgSrc: "/image/cbct/close.png",
    imgSrcActive: "/image/cbct/Active/Aclose.png",
    imgSrcHover: "/image/cbct/Hover/Hclose.png",
  },
]);
// 视图工具栏
let viewTool = reactive([
  {
    label: t("MovingImage"),
    name: "MovingImage",
    Active: false,
    independent: false,
    imgSrc: "/image/cbct/siderTool/移动.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A移动.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H移动.png",
  },
  {
    label: t("GrayImageReverseColor"),
    name: "BlackWhite",
    Active: false,
    independent: true,
    imgSrc: "/image/cbct/siderTool/灰度图像反色.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A灰度图像反色.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H灰度图像反色.png",
  },
  {
    label: t("DefaultWindowPosition"),
    name: "DefaultWindow",
    Active: false,
    independent: true,
    imgSrc: "/image/cbct/siderTool/设置默认窗框窗位.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A设置默认窗框窗位.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H设置默认窗框窗位.png",
  },
  {
    label: t("RDIWindowPosition"),
    name: "RDIWindow",
    Active: false,
    independent: false,
    imgSrc: "/image/cbct/siderTool/Roi窗框窗位.png",
    imgSrcActive: "/image/cbct/siderTool/Active/ARoi窗框窗位.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/HRoi窗框窗位.png",
  },
  // {
  //   label: t("3DVolumeofInterest"),
  //   name: "VolumeOfInterest",
  //   Active: false,
  //   independent: true,
  //   imgSrc: "/image/cbct/siderTool/3D感兴趣体积.png",
  //   imgSrcActive: "/image/cbct/siderTool/Active/A3D感兴趣体积.png",
  //   imgSrcHover: "/image/cbct/siderTool/Hover/H3D感兴趣体积.png",
  // },
  {
    label: t("LeadWire"),
    name: "LeadWire",
    Active: false,
    independent: true,
    imgSrc: "/image/cbct/siderTool/引导线.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A引导线.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H引导线.png",
  },
  {
    label: t("ZoomImage"),
    name: "ZoomImage",
    Active: false,
    independent: false,
    imgSrc: "/image/cbct/siderTool/放大.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A放大.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H放大.png",
  },
  {
    label: t("reset"),
    name: "reset",
    Active: false,
    independent: true,
    imgSrc: "/image/cbct/siderTool/恢复图像默认状态.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A恢复图像默认状态.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H恢复图像默认状态.png",
  },
  // {
  //   label: t("deleteAppointTool"),
  //   name: "deleteAppointTool",
  //   Active: false,
  //   independent: false,
  //   imgSrc: "/image/cbct/siderTool/删除工具.png",
  //   imgSrcActive: "/image/cbct/siderTool/Active/A删除工具.png",
  //   imgSrcHover: "/image/cbct/siderTool/Hover/H删除工具.png",
  // },
  {
    label: t("Information object saving"),
    name: "Information object saving",
    Active: false,
    independent: true,
    imgSrc: "/image/cbct/siderTool/信息对象保存.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A信息对象保存.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H信息对象保存.png",
  },
]);
//测量工具栏
let MeasureTool = reactive([
  // {
  //   label: t("deleteAllTool"),
  //   name: "deleteAllTool",
  //   Active: false,
  //   independent: true,
  //   imgSrc: "/image/cbct/siderTool/删除所有工具.png",
  //   imgSrcActive: "/image/cbct/siderTool/Active/A删除所有工具.png",
  // },
  {
    label: t("LinearMeasurement"),
    name: "LinearMeasurement",
    Active: false,
    independent: false,
    imgSrc: "/image/cbct/siderTool/直线测量.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A直线测量.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H直线测量.png",
  },
  {
    label: t("AngleMeasurement"),
    name: "AngleMeasurement",
    Active: false,
    independent: false,
    imgSrc: "/image/cbct/siderTool/角度测量.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A角度测量.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H角度测量.png",
  },
  {
    label: t("Dimensioning"),
    name: "Dimensioning",
    Active: false,
    independent: false,
    imgSrc: "/image/cbct/siderTool/文字备注.png",
    imgSrcActive: "/image/cbct/siderTool/Active/A文字备注.png",
    imgSrcHover: "/image/cbct/siderTool/Hover/H文字备注.png",
  },
]);
onMounted(() => {
  defaultAtive.value = "MPR";
});
// 切换头部标签页
function handleTopTaps(key) {
  defaultAtive.value = key;
  // execChange(key);
}
// 执行tab切换
// function execChange(key) {
//   SiderTool(key);
//   var self = this;
//   if (alreadly.indexOf(defaultAtive.value) == -1) {
//     alreadly.push(defaultAtive.value);
//   }
//   // 删除前一个页面的canvas画布，缓存数据
//   self.curChild().onHide();
//   self.curChild().isOpened = true;
//   defaultAtive.value = key;
//   // 创建当前页的canvas并将缓存数据渲染
//   curChild().onShow();
// }
</script>

<style lang="scss" scoped>
.mian {
  width: 100%;
  height: 100%;
  .topHeader {
    height: 40px;
    width: 100%;
    background-color: #151515;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .layoutMenu {
      width: 600px;
      display: flex;
      align-items: center;
      .el-menu.el-menu--horizontal {
        width: 600px;
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: none;
      }
      .el-menu--horizontal > .el-menu-item {
        border: none;
        height: 30px;
        line-height: 30px;
        margin-right: 8px;
        padding: 0 24px;
      }
      .is-active {
        background: #303030 !important;
        border: none;
        span {
          height: 100%;
          display: block;
          box-sizing: border-box;
          border-bottom: 2px solid #07c160;
        }
      }
      .el-menu-item {
        border-radius: 4px 4px 0px 0px;
      }
    }
    .PatientInformation {
      color: #fff !important;
    }
    .toolArea {
      width: 200px;
      height: 100%;
      margin-right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .userAvatar {
        width: 130px;
        height: 24px;
        line-height: 24px;
        .el-avatar {
          width: 24px;
          height: 24px;
        }
        span {
          vertical-align: middle; /* 垂直居中 */
          color: #fff;
        }
      }
      .vertical {
        height: 30px;
        width: 1px;
        background: #333333;
        // margin: 0 32px;
      }
    }
  }
  .container {
    width: 100% !important;
    height: calc(100% - 40px) !important;

    .containerView {
      width: 100%;
      background: #000;
      height: 100%;
      box-sizing: border-box;
    }
    .leftSidebar {
      width: 228px;
      height: 100%;
      float: left;
      z-index: 2000;
    }
  }
  .cursordefault {
    cursor: url("../../../assets/imgs/cursorStyle/cursor1.png") 10 10, auto;
  }
  .move {
    cursor: url("../../../assets/imgs/cursorStyle/cursor2.png") 10 10, auto;
  }
  .neResize {
    cursor: url("../../../assets/imgs/cursorStyle/cursor3.png") 10 10, auto;
  }
  .nResize {
    cursor: url("../../../assets/imgs/cursorStyle/cursor4.png") 10 10, auto;
  }
  .nwResize {
    cursor: url("../../../assets/imgs/cursorStyle/cursor5.png") 10 10, auto;
  }
  .wResize {
    cursor: url("../../../assets/imgs/cursorStyle/cursor6.png") 10 10, auto;
  }
  .zoomIn {
    cursor: url("../../../assets/imgs/cursorStyle/cursor7.png") 10 10, auto;
  }
  .networkIcon {
    cursor: url("../../../assets/imgs/cursorStyle/cursor8.png") 10 10, auto;
  }
  .rotatY {
    cursor: url("../../../assets/imgs/cursorStyle/cursor9.png") 10 10, auto;
  }
  .rotatZ {
    cursor: url("../../../assets/imgs/cursorStyle/cursor10.png") 10 10, auto;
  }
  .rotatX {
    cursor: url("../../../assets/imgs/cursorStyle/cursor11.png") 10 10, auto;
  }
  .orSoMobile {
    cursor: url("../../../assets/imgs/cursorStyle/cursor12.png") 10 10, auto;
  }
  .moveUpDown {
    cursor: url("../../../assets/imgs/cursorStyle/cursor13.png") 10 10, auto;
  }
  .arrowLines {
    cursor: url("../../../assets/imgs/cursorStyle/cursor14.png") 10 10, auto;
  }
  .lowerRightMove {
    cursor: url("../../../assets/imgs/cursorStyle/cursor15.png") 10 10, auto;
  }
  .hand {
    cursor: url("../../../assets/imgs/cursorStyle/cursor16.png") 10 10, auto;
  }
  .pointer {
    cursor: url("../../../assets/imgs/cursorStyle/cursor17.png") 10 10, auto;
  }
  .upMove {
    cursor: url("../../../assets/imgs/cursorStyle/cursor18.png") 10 10, auto;
  }
  .textCursor {
    cursor: url("../../../assets/imgs/cursorStyle/cursor19.png") 10 10, auto;
  }
  .NoteCursor {
    cursor: url("../../../assets/imgs/cursorStyle/cursor20.png") 10 10, auto;
  }
  .NumCursor {
    cursor: url("../../../assets/imgs/cursorStyle/cursor21.png") 10 10, auto;
  }
  .leftCursor {
    cursor: url("../../../assets/imgs/cursorStyle/cursor22.png") 10 10, auto;
  }
  .rightCursor {
    cursor: url("../../../assets/imgs/cursorStyle/cursor23.png") 10 10, auto;
  }
  .aimCursor {
    cursor: url("../../../assets/imgs/cursorStyle/cursor24.png") 10 10, auto;
  }
}
</style>